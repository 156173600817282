import { Box, Grid, Typography } from "@mui/material";
import DashboardContainer from "../../../core/components/DashboardContainer";
import AdminLayout from "../components/AdminLayout";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../core/components/Loader";
import { createData } from "../../../core/utils";
import { decisionDashboard } from "../../../store/Dashboard/dashboardService";
import OrderTable from "../AuditorDashboard/components/Recent";

const DecisionDashboard = () => {
  const user = useSelector((state: any) => state.auth.user);
  const { role } = user;
  const [responseData, setResponseData] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);

  const {
    execute,
    loading: loading,
    response,
  } = useAsyncRequest(decisionDashboard);

  const handleRes = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      const { recentList } = data;
      let list: any = [];
      setResponseData(recentList);
      recentList.map((item: any) => {
        list.push(
          createData(
            item.applicationNo ? item.applicationNo : "-",
            item.companyDetail.nameOfCompany,
            item.createdDate ? item.createdDate : "-",
            item.certification ? item.certification : "-",
            item.activeStatus ? item.activeStatus : "Active"
          )
        );
      });
      setData(list);
    }
  };

  useEffect(() => {
    execute("", handleRes);
  }, []);

  const card = (title: string, subTitle: string) => {
    return (
      <Box
        sx={{
          height: 100,
          width: 250,
          boxShadow: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          borderRadius: 2,
          backgroundColor: "white",
          pl: 2,
        }}
      >
        <Typography>{title}</Typography>
        <Typography>{subTitle}</Typography>
      </Box>
    );
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {card("Total Application", response?.data?.total)}
          {card("Completed Audits", response?.data?.completed)}
          {card("Draft Created", response?.data?.darfts)}
          {card("Decision Pending", response?.data?.decisionPending)}
        </Box>

        <Grid item xs={12} md={7} lg={8} mt={5} p={2}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Recent Applications</Typography>
            </Grid>
            <Grid item />
          </Grid>
          <OrderTable data={data} resData={responseData} role={role} />
        </Grid>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default DecisionDashboard;
