import { makeApiRequest } from "../../networking/apiClient";
import {
  ACCEPTAUDITOR,
  APPLICATIONBYID,
  APPLICATIONREVIEW,
  AUDITORFINDINGSUPDATE,
  AUDITPLANING,
  CREATEDRAFT,
  DECISIONACCEPT,
  DECISIONREJECT,
  FINDINGSAPPROVAL,
  FINDINGSREJECT,
  FINDINGSUPDATE,
  GETAPPLICATIONBYUSER,
  GETAPPLICATIONREVIEWBYCOMPANYID,
  GETCERTIFICATEBYAPPLICATION,
  GETCERTIFICATESBYUSER,
  ISSUECERTIFICATE,
  REJECTAUDITOR,
  UPDATEAUDITOR,
} from "../../networking/endpoints";

const customHeaders = {
  "Content-Type": "multipart/form-data;",
};

export const applicationReview = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      APPLICATIONREVIEW,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const auditPlaning = async (payload: any) => {
  try {
    const response = await makeApiRequest(AUDITPLANING, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const rejectAuditor = async (payload: any) => {
  try {
    const response = await makeApiRequest(REJECTAUDITOR, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const acceptAuditor = async (payload: any) => {
  try {
    const response = await makeApiRequest(ACCEPTAUDITOR, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatedAuditor = async (payload: any) => {
  try {
    const response = await makeApiRequest(UPDATEAUDITOR, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getApplicationReviewByCompanyId = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      GETAPPLICATIONREVIEWBYCOMPANYID,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findingsApproval = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      FINDINGSAPPROVAL,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findingsReject = async (payload: any) => {
  try {
    const response = await makeApiRequest(FINDINGSREJECT, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findingsUpdate = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      FINDINGSUPDATE,
      "POST",
      payload,
      customHeaders
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const auditorfindingsUpdate = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      AUDITORFINDINGSUPDATE,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getApplicationById = async (payload: any) => {
  try {
    const response = await makeApiRequest(APPLICATIONBYID, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createDraft = async (payload: any) => {
  try {
    const response = await makeApiRequest(CREATEDRAFT, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const issueCertificate = async (payload: any) => {
  try {
    const response = await makeApiRequest(ISSUECERTIFICATE, "POST", payload, customHeaders);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCertificateByApplication = async (payload: any) => {
  try {
    const response = await makeApiRequest(GETCERTIFICATEBYAPPLICATION, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getApplicationByUser = async (payload: any) => {
  try {
    const response = await makeApiRequest(GETAPPLICATIONBYUSER, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getCertificatesByUser = async (payload: any) => {
  try {
    const response = await makeApiRequest(GETCERTIFICATESBYUSER, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const decisonReject = async (payload: any) => {
  try {
    const response = await makeApiRequest(DECISIONREJECT, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const decisonAccept = async (payload: any) => {
  try {
    const response = await makeApiRequest(DECISIONACCEPT, "POST", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

