import { Box, Button, RadioGroup, Typography, useMediaQuery, useTheme } from "@mui/material";
import OnBoardingStepper from "../../../../core/components/Stepper/OnBoardingStepper";
import OnBoardingHeader from "../../Components/onBoardingHeader";
import { FontWeight, INPUTFIELDTYPE } from "../../../../core/types";
import { useNavigate } from "react-router-dom";
import { COMPANYDETAILS } from "../../../../router/config";
import { onBoardingStepperList } from "../../../../core/constants";
import { Formik } from "formik";
import { cerficateType, certificationInitialValue } from "../../Constants";
import CommonGrid from "../../Components/common/CommonGrid";
import { certificateSchema } from "../../../../core/validations/formSchema";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import {
  createApplication,
  getCertificationList,
} from "../../../../store/Oboarding/cetificationService";
import { useEffect, useState } from "react";
import Loader from "../../../../core/components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { applicationDetails } from "../../../../store/Oboarding/onBoardingSlice";
import TextInputLight from "../../../../core/components/Input/TextInputLight";

const Certificaions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);
  const [certificationType, setCertificationType] = useState([]);
  const [requiredCertificates, setRequiredCertificates] = useState<any>([]);
  const [otherChecked, setOtherChecked] = useState<any>(false);
  const [otherValue, setOtherValue] = useState<any>("");
  const [errorText, setErrorText] = useState<string>("");

  const { execute, loading } = useAsyncRequest(getCertificationList);

  const { execute: exe, loading: createLoading } =
    useAsyncRequest(createApplication);

  useEffect(() => {
    execute("", handleResponse);
  }, [navigate]);

  const handleResponse = (response: any) => {
    const { status, data } = response;
    if (status === 200) {
      setCertificationType(data);
    }
  };

  const handleCreateApplicationResponse = (response: any) => {
    const { status, data } = response;
    if (status === 200) {
      dispatch(applicationDetails(data));
      navigate(COMPANYDETAILS);
    }
  };

  const handleGrouping = (e: any) => {
    const { name, checked } = e.target;
    if (checked) {
      if (name == "Other") {
        setOtherChecked(true);
      } else {
        setRequiredCertificates([...requiredCertificates, name]);
      }
    } else {
      if (name == "Other") {
        setOtherChecked(false);
      } else {
        setRequiredCertificates(
          requiredCertificates.filter((cert: any) => cert !== name)
        );
      }
    }
  };

  return (
    <Box>
      <OnBoardingHeader />
      <Box sx={{ p: { xs: 2, md: 5 }, pt: { xs: 2, md: 3 } }}>
        <OnBoardingStepper list={onBoardingStepperList} active={1} />
      </Box>
      <Loader open={loading || createLoading} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          pl: { xs: 2, md: 6 },
          pr: { xs: 2, md: 6 },
          pt: 1,
        }}
      >
        <Formik
          initialValues={certificationInitialValue}
          validationSchema={certificateSchema}
          onSubmit={(value: any) => {
            setErrorText("");
            if (requiredCertificates.length != 0) {
              setErrorText("");
              const payload = {
                userEmail: user.email,
                certification: value.radio,
                requiredCertifications: requiredCertificates,
                other: otherChecked ? value.other : null,
              };
              exe(payload, handleCreateApplicationResponse);
            } else {
              setErrorText("Aleast one certificaton required");
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box>
                <RadioGroup
                  name="radio"
                  value={values.radio}
                  onChange={handleChange}
                >
                  <CommonGrid
                    handleChange={handleChange}
                    value={values}
                    paddingTop={0}
                    onBlur={handleBlur}
                    error={errors}
                    list={cerficateType}
                    touched={touched}
                    filedType={INPUTFIELDTYPE.RADIO}
                    noOfRowStyle={16}
                  />
                </RadioGroup>
              </Box>
              {values.radio === "fresh" || values.radio === "scopeExtension" ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{ mt: { xs: 2, md: 2 } }}
                  >
                    <Typography variant="h6" fontWeight={FontWeight.BOLD}>
                      Please select the types of certifications required
                    </Typography>
                  </Box>
                  <CommonGrid
                    handleChange={handleChange}
                    value={values}
                    paddingTop={5}
                    onBlur={handleBlur}
                    error={errors}
                    list={certificationType}
                    touched={touched}
                    filedType={INPUTFIELDTYPE.CHECKBOX}
                    onChangeCertificationList={handleGrouping}
                  />

                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{ mt: { xs: 2, md: 3 } }}
                  >
                    {otherChecked && (
                      <TextInputLight
                        width={400}
                        name="other"
                        value={otherValue}
                        onChange={(value: any) => {
                          setFieldValue("other", value.target.value);
                          setOtherValue(value.target.value);
                        }}
                      />
                    )}
                    {errorText && (
                      <Typography color={"red"}>{errorText}</Typography>
                    )}
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{ mt: { xs: 2, md: 8 } }}
                    mb={isMobile ? 10 : 2}
                  >
                    <Button variant="contained" color="primary" type="submit">
                      Proceed
                    </Button>
                  </Box>
                </>
              ) : null}
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default Certificaions;
