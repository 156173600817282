import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DashboardContainer from "../../core/components/DashboardContainer";
import AdminLayout from "../dashboard/components/AdminLayout";
import DateTimePicker from "../../core/components/DatePicker";
import { FontWeight, INPUTFIELDTYPE, TextFieldType } from "../../core/types";
import { useEffect, useState } from "react";
import DropdownMenu from "../../core/components/DropDownMenu";
import TextInputLight from "../../core/components/Input/TextInputLight";
import { auditTypes } from "./constants";
import AuditPlanTable from "./auditPlanTable";
import { Formik } from "formik";
import { adminReviewShcema } from "../../core/validations/formSchema";
import { APPLICATIONSTATUS } from "../../router/config";
import { auditPlaningInitialValues } from "../adminReview/constants";
import useAsyncRequest from "../../networking/useAsyncRequest";
import { getAuditorListDrop } from "../../store/AuditorList/auditorListService";
import { findLabelByName, getEmailByName } from "../../core/utils";
import { auditPlaning } from "../../store/Application/applicationService";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../core/components/Loader";
import { useSelector } from "react-redux";
import moment from "moment";
import { getAuditPlainByApplicationId } from "../../store/AuditorFlow/auditorListService";
import AuditPlan from "../decisionFlow/AuditPlan";
import UpdateAuditor from "./components/UpdateAuditor";
import CommonGrid from "../onBoarding/Components/common/CommonGrid";

const AuditPlaning = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: any) => state.auth.user);
  const { data } = location.state;
  const { applicationId, companyDetail } = data;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [responseData, setResponseData] = useState<any[]>([]);
  const [typeOfAuditList, setTypeOfAuditList] = useState<any>([]);
  const { execute, loading } = useAsyncRequest(getAuditorListDrop);

  const [exisitingPlan, setExistingPlan] = useState<any>();

  const { execute: submit, loading: submitLoading } =
    useAsyncRequest(auditPlaning);

  const { execute: existing, loading: existingLoading } = useAsyncRequest(
    getAuditPlainByApplicationId
  );

  const handleAuditResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      setExistingPlan(data);
    } else {
      setExistingPlan(null);
    }
  };

  const payload = {
    applicationId: applicationId,
  };

  useEffect(() => {
    existing(payload, handleAuditResponse);
  }, []);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      setResponseData(data);
    }
  };

  const onResponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      navigate(APPLICATIONSTATUS, { replace: true });
    }
  };

  useEffect(() => {
    execute("", handleResponse);
  }, []);

  const headLabel = (label: string) => {
    return (
      <Typography
        fontWeight={FontWeight.MEDIUM}
        fontSize={16}
        color={"#2c2c2c"}
      >
        {label}
      </Typography>
    );
  };

  const typeAuditGrid = (label: any, handleChange: any) => {
    return (
      <Grid item xs={2} sm={4} md={4}>
        <FormControlLabel
          control={<Checkbox name={label} onChange={handleChange} />}
          label={label}
        />
      </Grid>
    );
  };

  const auditorDropDown = (
    label: any,
    menuLabel: any,
    handleChange: any,
    value: any,
    name: any
  ) => {
    return (
      <Grid item xs={2} sm={4} md={4}>
        <DropdownMenu
          label={label}
          menuLabel={menuLabel}
          handleChange={handleChange}
          value={value}
          width={"100%"}
          name={name}
          option={responseData}
        />
      </Grid>
    );
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        {exisitingPlan ? (
          <Box>
            {exisitingPlan.status === "UPDATED" ? (
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent="center"
                flexDirection={"column"}
              >
                <Typography fontWeight={FontWeight.BOLD}>
                  Auditor Updated, User needs to accept audit to continue
                </Typography>
              </Box>
            ) : exisitingPlan.status === "ACCEPTED" ? (
              <Box></Box>
            ) : (
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent="center"
                flexDirection={"column"}
              >
                <Typography fontWeight={FontWeight.BOLD}>
                  User requested for auditor change
                </Typography>
                <UpdateAuditor
                  auditorData={responseData}
                  applicationId={applicationId}
                  onSuccess={() => {
                    existing(payload, handleAuditResponse);
                  }}
                />
              </Box>
            )}
            <AuditPlan applicationId={applicationId} data={data} />
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="start"
            flexDirection={"column"}
            sx={{ mt: { xs: 2, md: 1 } }}
          >
            <Loader open={loading || submitLoading || existingLoading} />
            <Formik
              initialValues={auditPlaningInitialValues}
              validationSchema={adminReviewShcema}
              onSubmit={(value: any) => {
                const payload = {
                  applicationId: applicationId,
                  companyId: companyDetail.id,
                  typeOfAudit: findLabelByName(value.auditType, auditTypes),
                  leadAuditorEmail: getEmailByName(
                    value.leadAuditor,
                    responseData
                  ),
                  auditorEmail: getEmailByName(value.auditor, responseData),
                  preparedBy: user.email,
                  ...value,
                };
                submit(payload, onResponse);
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
                handleBlur,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <DateTimePicker
                      label="Date Of Audit"
                      name={"auditDate"}
                      onChange={(val: any) =>
                        setFieldValue(
                          "auditDate",
                          moment(val).format("DD/MM/YYYY")
                        )
                      }
                    />
                  </Box>
                  {headLabel("Assessment Team")}
                  <Grid
                    container
                    spacing={isMobile ? 2 : 4}
                    alignItems={"baseline"}
                    columns={{ xs: 2, sm: 12, md: 12 }}
                    sx={{
                      paddingTop: 3,
                      paddingBottom: 5,
                    }}
                  >
                    {auditorDropDown(
                      "Lead Auditor",
                      "Select Auditor",
                      handleChange,
                      values.leadAuditor,
                      "leadAuditor"
                    )}
                    {auditorDropDown(
                      "Auditor",
                      "Select Auditor",
                      handleChange,
                      values.auditor,
                      "auditor"
                    )}
                    <Box pl={isMobile ? 2 : 4} mt={3} width={"100%"}>
                      <TextInputLight
                        type={TextFieldType.TEXT}
                        name="observer"
                        label="Observer"
                        onChange={handleChange}
                        width={isMobile ? "100%" : 370}
                      />
                    </Box>
                  </Grid>
                  {headLabel("Type of Audit")}
                  <Box mt={3} mb={3}>
                    <RadioGroup
                      name="auditType"
                      value={values.auditType}
                      onChange={handleChange}
                    >
                      <CommonGrid
                        handleChange={handleChange}
                        value={values}
                        paddingTop={0}
                        onBlur={handleBlur}
                        error={errors}
                        list={auditTypes}
                        touched={touched}
                        filedType={INPUTFIELDTYPE.RADIO}
                        noOfRowStyle={16}
                      />
                    </RadioGroup>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="start"
                    flexDirection={"row"}
                    mt={2}
                  >
                    <Box mt={-1} width={"100%"}>
                      <TextInputLight
                        type={TextFieldType.TEXT}
                        name="other"
                        label="Other"
                        onChange={handleChange}
                        width={isMobile ? "100%" : 370}
                      />
                    </Box>
                  </Box>
                  <Box mt={4} mb={5}>
                    <TextInputLight
                      type={TextFieldType.TEXT}
                      name="language"
                      label="Language"
                      onChange={handleChange}
                      width={ isMobile ? "100%" : 370}
                    />
                  </Box>
                  {headLabel("Assessment Schedule")}
                  <AuditPlanTable
                    handleChange={handleChange}
                    value={values}
                    setFieldValue={setFieldValue}
                    options={responseData}
                  />
                  <Box
                    pt={10}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        )}
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AuditPlaning;
