import { Box, Grid, IconButton, Typography } from "@mui/material";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { getAuditPlainByApplicationId } from "../../../store/AuditorFlow/auditorListService";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontWeight } from "../../../core/types";
import Loader from "../../../core/components/Loader";
import TextWithLabel from "../../../core/components/TextWithLabel";
import { handleDownloadPdf } from "../../../core/utils";
import AuditTimeline from "./components";
import AuditorAccept from "../../userFlow/AuditSchedule/components/AcceptAuditor";
import AuditorReject from "../../userFlow/AuditSchedule/components/RejectAuditor";
import { useSelector } from "react-redux";
import { USERTYPE } from "../../../core/constants";
import DashboardContainer from "../../../core/components/DashboardContainer";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AuditPlan = ({ data }: any) => {
  const [responseData, setResponseData] = useState<any>([]);
  const user = useSelector((state: any) => state.auth.user);
  const { role } = user;

  const { applicationId } = data;

  const { execute, loading } = useAsyncRequest(getAuditPlainByApplicationId);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      setResponseData(data);
    } else {
      setResponseData(null);
    }
  };

  const payload = {
    applicationId: applicationId,
  };

  const onSuccess = () => {
    execute(payload, handleResponse);
  };

  useEffect(() => {
    execute(payload, handleResponse);
  }, [applicationId]);

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && <TextWithLabel title={title} subTitle={subTitle} />}
      </Grid>
    );
  };

  return (
    <Box>
      <Loader open={loading} />
      <Box
        display={"flex"}
        mt={2}
        justifyContent={"flex-end"}
        alignItems={"baseline"}
      >
        <IconButton onClick={() => handleDownloadPdf("page-content")}>
          <Typography mr={1}>Download</Typography>
          <FileDownloadRoundedIcon />
        </IconButton>
      </Box>
      {responseData !== null ? (
        <Box id="page-content">
          <DashboardContainer>
          {responseData && (
            <Grid
              container
              spacing={4}
              alignItems={"baseline"}
              columns={{ xs: 2, sm: 12, md: 16 }}
              sx={{
                paddingTop: 2,
                paddingBottom: 2,
              }}
            >
              {detailField("Date Of Audit", responseData.auditDate)}
              {detailField("Lead Auditor", responseData.leadAuditor)}
              {detailField("Auditor", responseData.auditor)}
              {detailField("Observer", responseData.observer)}
              {detailField("Other", responseData.other)}
              {detailField("Language", responseData.language)}
              {detailField("Type of Audit", responseData.typeOfAudit)}
            </Grid>
          )}
          </DashboardContainer>
          {/* <Box>
            <Typography pt={1} fontWeight={FontWeight.BOLD}>
              Type of Audit
            </Typography>
            <Box display={"flex"} pt={2}>
              {responseData.typeOfAudit &&
                responseData.typeOfAudit.map((item: any, index: any) => {
                  return (
                    <Typography
                      pr={2}
                      fontSize={16}
                      fontWeight={FontWeight.MEDIUM}
                    >
                      {index + 1}. {item}
                    </Typography>
                  );
                })}
            </Box>
          </Box> */}
          {responseData?.status && (
            <Box
              mt={2}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                color={responseData?.status === "REJECTED" ? "red" : "green"}
                variant="h6"
                fontWeight={FontWeight.BOLD}
              >
                {responseData?.status === "REJECTED"
                  ? "Audit is Rejected"
                  : responseData?.status === "UPDATED"
                  ? "Audit Updated"
                  : responseData?.status === "ACTIVE"
                  ? ""
                  : "Audit is accepted"}
              </Typography>
            </Box>
          )}
          {role === USERTYPE.USER && (
            <Box>
              {(responseData?.status === "UPDATED" ||
                responseData?.status === "ACTIVE") && (
                <Box
                  mt={2}
                  width={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box pr={1}>
                    <AuditorAccept
                      applicationId={applicationId}
                      onSuccess={onSuccess}
                    />
                  </Box>
                  <AuditorReject
                    applicationId={applicationId}
                    onSuccess={onSuccess}
                  />
                </Box>
              )}
            </Box>
          )}
          <Box
            mt={4}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant="h5" fontWeight={FontWeight.BOLD}>
              Audit Schedule
            </Typography>
          </Box>
          <DashboardContainer>
           <AuditTimeline data={responseData} />
          </DashboardContainer>
          {/* {scheduleComponent(responseData.openMeetingDate, "Opening Meeting")}
          {scheduleComponent(
            responseData.dayOneFirstDate,
            responseData.dayOneFirst
          )}
          {scheduleComponent(
            responseData.dayOneSecondDate,
            responseData.dayOneSecond
          )}
          <Typography
            variant="h5"
            fontWeight={FontWeight.BOLD}
            textAlign={"center"}
            p={2}
            color={"green"}
          >
            Day One
          </Typography>
          {scheduleComponent(
            responseData.dayOneBeforeBreakDate,
            "QMS Auditor :" +
              responseData.dayOneBeforeBreakQmsAuditor +
              " || " +
              "Technical Auditor :" +
              responseData.dayOneBeforeBreakTechnicalAuditor
          )}
          {scheduleComponent(responseData.dayOnebreakDateTime, "Day One Break")}
          {scheduleComponent(
            responseData.dayOneAfterBreakDate,
            "QMS Auditor :" +
              responseData.dayOneAfterBreakQmsAuditor +
              " || " +
              "Technical Auditor :" +
              responseData.dayOneAfterBreakTechnicalAuditor
          )}
          <Typography
            variant="h5"
            fontWeight={FontWeight.BOLD}
            textAlign={"center"}
            p={2}
            color={"green"}
          >
            Day Two
          </Typography>
          {scheduleComponent(
            responseData.dayTwoBeforeBreakDate,
            "QMS Auditor :" +
              responseData.dayTwoBeforeBreakQmsAuditor +
              " || " +
              "Technical Auditor :" +
              responseData.DayTwoBeforeBreakTechnicalAuditor
          )}
          {scheduleComponent(responseData.dayTwobreakDateTime, "Day Two Break")}
          {scheduleComponent(
            responseData.dayTwoAfterBreakDate,
            "QMS Auditor :" +
              responseData.dayTwoAfterBreakQmsAuditor +
              " || " +
              "Technical Auditor :" +
              responseData.dayTwoAfterBreakTechnicalAuditor
          )}
          {scheduleComponent(
            responseData.dayTwobreakDateTime,
            responseData.dayTwoBeforeClosing
          )}
          {scheduleComponent(
            responseData.closingMeetingDate,
            "Closing Meeting"
          )} */}
        </Box>
      ) : (
        <Box>
          <DashboardContainer>
           <Typography>Audit not planned yet</Typography>
          </DashboardContainer>
        </Box>
      )}
    </Box>
  );
};

export default AuditPlan;
