import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import {
  deleteCertification,
  getAllCertificationList,
  updateCertification,
} from "../../../store/Oboarding/cetificationService";
import { useEffect, useState } from "react";
import Loader from "../../../core/components/Loader";
import { Box, FormControlLabel, IconButton, Typography } from "@mui/material";
import { FontWeight } from "../../../core/types";
import AddUpdateCertifications from "./addUpdateCerifications";
import IOSSwitch from "../../../core/components/Switch";

const AllCertificationList = () => {
  const [certificationType, setCertificationType] = useState([]);

  useEffect(() => {
    execute("", handleResponse);
  }, []);

  const handleResponse = (response: any) => {
    const { status, data } = response;
    if (status === 200) {
      setCertificationType(data);
    }
  };

  const handleRes = (response: any) => {
    const { status, data } = response;
    if (status == 200) {
      execute("", handleResponse);
    }
  };

  const { execute, loading } = useAsyncRequest(getAllCertificationList);

  const { execute: deleteApi, loading: deleteLoading } =
    useAsyncRequest(deleteCertification);

  const { execute: updateApi, loading: updateLoading } =
    useAsyncRequest(updateCertification);

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading || deleteLoading || updateLoading} />
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"end"}
          pb={3}
        >
          <Typography fontWeight={FontWeight.BOLD} variant="h5">
            Certifications
          </Typography>
          <AddUpdateCertifications
            trigger={() => {
              execute("", handleResponse);
            }}
          />
        </Box>
        <Box>
          {certificationType.map((item: any, index: any) => {
            return (
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                p={1}
              >
                <Typography
                  sx={{wordWrap: 'break-word', maxWidth:  { xs: '155px', md: '700px'}, fontSize: {xs: '0.85rem', md: '1rem'}}}
                >
                  {item.label}
                </Typography>
                <Box>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked={!item.disabled}
                        onClick={() => {
                          const payload = {
                            name: item.name,
                            disabled: !item.disabled,
                          };
                          updateApi(payload, handleRes);
                        }}
                      />
                    }
                    label=""
                  />
                  {item.name !== "other" ? (
                    <IconButton
                      sx={{ color: "red" }}
                      onClick={() => {
                        const payload = {
                          name: item.name,
                        };
                        deleteApi(payload, handleRes);
                      }}
                    >
                      <DeleteForeverRoundedIcon />
                    </IconButton>
                  ) : (
                    <Box width={100}></Box>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AllCertificationList;
