import { apiClient, makeApiRequest } from "../../networking/apiClient";
import {
  ACTIVEAPPLICATIONS,
  ACTIVEAPPLICATIONSAUDITOR,
  ADMINDASHBOARD,
  DECISIONDASHBOARD,
} from "../../networking/endpoints";

export const getActiveApplication = async () => {
  try {
    const response = await apiClient.get(ACTIVEAPPLICATIONS);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getActiveApplicationAuditor = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      ACTIVEAPPLICATIONSAUDITOR,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getApplicationByUser = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      ACTIVEAPPLICATIONSAUDITOR,
      "POST",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const decisionDashboard = async (payload: any) => {
  try {
    const response = await makeApiRequest(
      DECISIONDASHBOARD,
      "GET",
      payload,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const adminDashboard = async (payload: any) => {
  try {
    const response = await makeApiRequest(ADMINDASHBOARD, "GET", payload, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};
