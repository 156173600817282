import { Box, Tabs, Tab,  useMediaQuery, useTheme, } from "@mui/material";
import DashboardContainer from "../../../../core/components/DashboardContainer";
import AdminLayout from "../AdminLayout";
import { useEffect, useState } from "react";
import CompanyDetailComponent from "../../../../core/components/CompanyDetailComponent";
import { useLocation } from "react-router-dom";
import ApplicationReviewView from "../../../decisionFlow/ApplicationReviewView";
import RequiredCheckList from "../../../decisionFlow/RequiredList";
import AuditFindings from "../../../decisionFlow/AuditFindings";
import AuditPlan from "../../../decisionFlow/AuditPlan";
import Overview from "../../../decisionFlow/Overview";
import AuditFindingsView from "../../../decisionFlow/AuditFindings";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import { getApplicationById } from "../../../../store/Application/applicationService";
import Loader from "../../../../core/components/Loader";
import StageCheckList from "../../../decisionFlow/StageCheckListView";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const DecisionApplicationDetailView = () => {
  const location = useLocation();
  const { data: details } = location.state;
  const [value, setValue] = useState(0);
  const [data, setData] = useState();
  const [trigger, setTrigger] = useState(false)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  const { companyId, applicationId } = details;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleRes = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      setData(data);
    }
  };

  const {
    execute,
    loading: loading,
    response,
  } = useAsyncRequest(getApplicationById);

  useEffect(() => {
    const payload = {
      applicationId: applicationId,
    };
    execute(payload, handleRes);
  }, [location, trigger]);

  return (
    <AdminLayout>
        <Loader open={loading} />
        {data && (
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant={isMobile ? "scrollable" : "fullWidth"}
                scrollButtons={isMobile ? true : false}
                aria-label="basic tabs example"
              >
                <Tab label="Overview" {...a11yProps(0)} />
                <Tab label="Company Details" {...a11yProps(1)} />
                <Tab label="Review Details" {...a11yProps(2)} />
                <Tab label="Audit Plan" {...a11yProps(3)} />
                <Tab label="Stage One CheckList" {...a11yProps(4)} />
                <Tab label="Required Check Lists" {...a11yProps(5)} />
                <Tab label="Findings" {...a11yProps(6)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Overview data={data} trigger={()=>{setTrigger(!trigger)}}/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <CompanyDetailComponent data={details} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <ApplicationReviewView data={companyId} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <AuditPlan data={details} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <StageCheckList />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <RequiredCheckList />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
              <AuditFindingsView data={applicationId} />
            </CustomTabPanel>
          </Box>
        )}
    
    </AdminLayout>
  );
};

export default DecisionApplicationDetailView;
