import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import TextInputLight from "../../../../core/components/Input/TextInputLight";

const SiteDetails = ({ setFieldValue }: any) => {
  const [row, setRow] = useState([0]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  return (
    <Box
      ml={ isMobile ? 2 : 5}
      mt={2}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
    >
      {row.map((item: any, index: number) => {
        return (
          <TextInputLight
            label={index === 0 ? "Main Site" : `${"Site " + index}`}
            width={300}
            name={"siteDetails"}
            textFieldHeigt={60}
            onChange={(val: any) => {
              setFieldValue(`siteDetails.${[index]}.item`, val.target.value);
              setFieldValue(
                `siteDetails.${[index]}.label`,
                `${index === 0 ? "Main Site" : `${"Site " + index}`}`
              );
            }}
          />
        );
      })}
      <Box
        width={300}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"end"}
        pt={2}
      >
        <Button
          onClick={() => {
            setRow((prev: any) => [...prev, prev.length + 1]);
          }}
        >
          + Add More
        </Button>
      </Box>
    </Box>
  );
};

export default SiteDetails;
