import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Modal,
  Alert,
  Divider,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  Fastfood as FastfoodIcon,
  Public as PublicIcon,
  Badge as BadgeIcon,
} from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { gapi } from "gapi-script";
import { FontWeight } from "../../../core/types";
import BaseAuthTemplate from "../../../core/components/CommonTemplate/BaseAuthTemplate";
import { homebg } from "../../../core/Images";
import { keyframes, useTheme } from "@mui/system";
import { useState, useEffect } from "react";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { verifyEmail } from "../../../store/Auth/authService";
import Loader from "../../../core/components/Loader";
import SignInForm from "../SignIn/Components/SigninForm";
import { USERTYPE } from "../../../core/constants";
import useMediaQuery from "@mui/material/useMediaQuery";

// Keyframe animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const zoomIn = keyframes`
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

// Component
const LandingPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<any>(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isUserLogin, setIsUserLogin] = useState(true);

  const emailToken = searchParams.get("emailToken");

  const { execute, loading } = useAsyncRequest(verifyEmail);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status === 200) {
      setMessage("Verification Successful, Please login to continue");
      setOpen(true);
      setStatus(true);
    } else {
      setMessage("Verification Failed, Please try again");
      setOpen(true);
      setStatus(false);
    }
  };

  useEffect(() => {
    if (emailToken) {
      const payload = {
        emailToken: emailToken,
      };

      execute(payload, handleResponse);
    }
  }, [emailToken]);

  const alert = () => {
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        closeAfterTransition
      >
        <Box p={60} sx={style}>
          <Alert severity={status ? "success" : "error"}>{message}</Alert>
          <Button onClick={onClose} sx={{ marginTop: 4 }} variant="contained">
            Close
          </Button>
        </Box>
      </Modal>
    );
  };

  const clientId =
    "830136945700-4sukjrick8hl7m10rlj7al7jhgfmntvo.apps.googleusercontent.com";

  const onClose = () => {
    setOpen(false);
  };

  const onSuccess = (res: any) => {
    console.log("Success", res);
  };

  const onFailure = (res: any) => {
    console.log("Success", res);
  };

  useEffect(() => {
    const start = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", start);
  }, []);

  const handleToggleChange = (event: any) => {
    setIsUserLogin(event.target.checked);
  };

  return (
    <BaseAuthTemplate>
      <Loader open={loading} />
      {alert()}

      <Box
        sx={{
          //backgroundImage: `url(${homebg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "5rem",
          animation: `${fadeIn} 1s ease-in-out`,
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box
          p={3}
          width={isMobile ? "90%" : "20%"}
          minWidth={350}
          sx={{
            background: "#eff6fe",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            borderRadius: "5px",
          }}
        >
          {/* <SignInForm type={USERTYPE.USER} /> */}
          {isMobile && ( // Show toggle only on mobile screens
            <FormControlLabel
              control={
                <Switch
                  checked={isUserLogin}
                  onChange={handleToggleChange}
                  color="primary"
                />
              }
              label={
                isUserLogin ? "Switch to Admin Login" : "Switch to User Login"
              }
              sx={{ mb: 2 }}
            />
          )}

          {/* Render only the relevant SignInForm based on the toggle */}
          <SignInForm type={isUserLogin ? USERTYPE.USER : undefined} />
        </Box>
        {!isMobile && (
          <Box display="flex" alignItems="center" height="60vh">
            <Box pl={3} />
            <Divider orientation="vertical" flexItem />
            <Box pl={3} />
          </Box>
        )}
        {!isMobile && (
          <Box
            p={3}
            width={"20%"}
            minWidth={350}
            height={485}
            sx={{
              background: "#F5F5F5",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              borderRadius: "5px",
            }}
          >
            <SignInForm />
          </Box>
        )}
      </Box>
    </BaseAuthTemplate>
  );
};

export default LandingPage;
