//auth
export const LOGIN = "auth/login";
export const GOOGLELOGIN = "auth/googlelogin";
export const REGISTRATION = "auth/register";
export const VERIFYEMAIL = "auth/verifyemail";
export const RESETPASSWORD = "auth/resetpassword";
export const DELETEUSER = "auth/deleteuser";
export const CHANGEPASSWORD = "auth/changepassword";

//onboarding
export const CERTIFICATIONLIST = "certification/getcertificationlist";
export const CREATEAPPLICATION = "application/application";
export const GETAPPLICATIONDETAILSBYMAIL = "application/getapplicationbyemail";
export const ADDCOMPANYDETAIL = "companydetail/addcompanydetail";
export const GETQUOTATION = "quotation/getquotationbyapplication";
export const ACCEPTQUOTATION = "quotation/acceptquotation";
export const REQUESTCHANGEINQUOTATION = "quotation/requestchange";
export const MAKEPAYMENT = "application/makepayment";
export const AGREEMENT = "application/agreement";
export const ACTIVEAPPLICATIONS = "application/activeapplication";
export const ACTIVEAPPLICATIONSAUDITOR = "application/getapplicationbyauditor";
export const CREATEQUOTATION = "quotation/addquotation";
export const GETADMINISTRATORLIST = "auth/administratorlist";
export const GETAUDITORLIST = "auth/auditorlist";
export const APPLICATIONREVIEW = "application/applicationreview";
export const AUDITPLANING = "application/auditplaning";
export const GETAPPLICATIONREVIEWBYCOMPANYID =
  "application/getapplicationreviewbycompanyid";
export const STANGEONEAUDITCHECKLIST = "application/auditchecklist";
export const REQUIREDCHECKLIST = "application/requiredchecklist";
export const STAGECHECKLIST = "application/getauditchecklistbyapplication";
export const GETREQUIREDLIST = "application/getrequiredchecklist";
export const ASSESSMENTFINDINGS = "application/auditassessment";
export const GETASSESSMENTFINDINGS =
  "application/getauditassessmentbyapplicationid";
export const OPENCLOSEMEETINGCHECK = "application/openclosemeetingcheck";
export const FINDINGSAPPROVAL = "application/findingsapproval";
export const FINDINGSREJECT = "application/findingsreject";
export const GETAUDITPLANING = "application/getauditplainingbyapplicationid";
export const AUDITCHECKLISTFILL = "application/auditchecklistfill";
export const SENDMESSAGE = "communication/sendMessage";
export const GETMESSAGES = "communication/getMessages";

export const APPLICATIONBYID = "application/getapplicationbyid";

//user
export const FINDINGSUPDATE = "application/findingsupdate";
export const REJECTAUDITOR = "application/rejectauditor";
export const ACCEPTAUDITOR = "application/acceptauditor";
export const GETCERTIFICATEBYUSER = "application/getcertifcatebyuser";
export const GETAPPLICATIONBYUSER = "application/getapplicationbyuseremail";
export const GETCERTIFICATESBYUSER = "application/getcertifcatebyuser";

//Auditor
export const AUDITORDASHBOARD = "application/auditdashboard";
export const AUDITORFINDINGSUPDATE = "application/audiorfindingsupdate";
export const GETAUDITORCHECKLIST = "certification/getauditorchecklist";

//decision
export const CREATEDRAFT = "application/createdraft";
export const ISSUECERTIFICATE = "application/issuecertificate";
export const DECISIONDASHBOARD = "dashboard/decisiondashboard";
export const GETCERTIFICATEBYAPPLICATION = "application/getcertifcatebyapplication";
export const DECISIONACCEPT = "application/decsionaccept";
export const DECISIONREJECT = "application/decsionReject";

//admin
export const ADMINDASHBOARD = "dashboard/admindashboard";
export const ADDCERTIFICATION = "certification/addcertificationlist";
export const DELETECERTIFICATION = "certification/deletecertificationlist";
export const UPDATECERTIFICATION = "certification/updatecertificationlist";
export const ALLCERTIFICATION = "certification/getallcertificationlist";
export const ADDAUDITORCHECKLIST = "certification/addauditorchecklist";
export const GETALLAUDITORCHECKLIST = "certification/getallauditorchecklist";
export const UPDATEAUDITORCHECKLIST = "certification/updateauditorchecklist";
export const DELETEAUDITORCHECKLIST = "certification/deleteauditorchecklist";
export const UPDATEAUDITOR = "application/updateauditor";
