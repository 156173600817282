import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OnBoardingStepper from "../../../../core/components/Stepper/OnBoardingStepper";
import { onBoardingStepperList } from "../../../../core/constants";
import OnBoardingHeader from "../../Components/onBoardingHeader";
import { useNavigate } from "react-router-dom";
import { REVIEW } from "../../../../router/config";
import QuotationDetails from "../../Components/quotationDetails";
import { quotationDetails } from "../../Constants";
import { FontWeight } from "../../../../core/types";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import {
  acceptQuotation,
  getQuoation,
} from "../../../../store/Oboarding/quotationServices";
import Loader from "../../../../core/components/Loader";
import inReview from "../../../../Icons/svg/inReview.svg";
import RequestChange from "./components/RequestChangeAlert";

const Quotation = () => {
  const navigate = useNavigate();

  const [isReview, setIsReview] = useState(false);
  const [data, setData] = useState<any>();
  const [res, setRes] = useState<any>();

  const applicationDetails = useSelector(
    (state: any) => state.onBoarding.details
  );

  const { application } = applicationDetails || {};

  const { applicationId } = application || {};

  const { execute, loading, response } = useAsyncRequest(getQuoation);
  const { execute: executeAcceptQuotation, loading: accptLoading } =
    useAsyncRequest(acceptQuotation);

  useEffect(() => {
    const payload = {
      applicationId: applicationId,
    };

    execute(payload, handleResponse);
  }, []);

  const handleResponse = (response: any) => {
    const { status, data } = response;
    if (status == 240) {
      setIsReview(false);
    } else if (status == 200) {
      setIsReview(true);
      setRes(data);
      const updatedBillBreakUp = quotationDetails.billBreakUp
        .filter((item) => data.hasOwnProperty(item.name))
        .map((item) => ({
          ...item,
          amount: data[item.name],
        }));
      setData(updatedBillBreakUp);
    }
  };

  const handleAccpetResponse = (res: any) => {
    const { status, data } = response;
    if (status == 200) {
      navigate(REVIEW);
    }
  };

  return (
    <Box>
      <OnBoardingHeader />
      <Box
        sx={{
          paddingLeft: { xs: 2, md: 5 },
          paddingRight: { xs: 2, md: 5 },
          paddingTop: { xs: 2, md: 3 },
        }}
      >
        <OnBoardingStepper list={onBoardingStepperList} active={3} />
      </Box>
      <Loader open={loading || accptLoading} />
      {!isReview ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <img src={inReview} style={{ paddingTop: 180 }} />
          <Typography
            variant={"h6"}
            fontWeight={FontWeight.BOLD}
            width={325}
            textAlign={"center"}
            pt={5}
          >
            Your Quotation is under process, a confirmation mail will be send to
            your registered E-mail ID
          </Typography>
          <Typography variant={"subtitle2"} textAlign={"center"} pt={2}>
            The process will take up to 2-3 business days
          </Typography>
        </Box>
      ) : (
        <Box>
          <Box
            display="flex"
            justifyContent="center"
            sx={{ pt: 5, mt: { xs: 2, md: 4 } }}
          >
            <Typography variant={"subtitle2"} fontWeight={FontWeight.BOLD}>
              Your Quotation has been processed
            </Typography>
          </Box>
          {data && <QuotationDetails quotationBreakUp={data} />}
          <Box
            pt={1}
            pl={5}
            pr={4.5}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              width={"50%"}
              justifyContent={"space-between"}
            >
              <Typography width={"80%"} variant={"subtitle2"}>
                GST at the prevailing Rate applicable shall be chargedextra
              </Typography>
              <Typography variant={"subtitle2"}>{res.gst}</Typography>
            </Box>
          </Box>
          <Box
            pt={2}
            pl={5}
            pr={4.5}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              width={"50%"}
              justifyContent={"space-between"}
            >
              <Typography
                width={"80%"}
                variant={"subtitle2"}
                fontWeight={FontWeight.BOLD}
              >
                Total Fees for 3 years of Certificate Validity Period
              </Typography>
              <Typography variant={"subtitle2"} fontWeight={FontWeight.BOLD}>
                {res.total}
              </Typography>
            </Box>
          </Box>
          {res?.changeRequestStatus === "CHANGEREQUESTED" ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              mt={2}
              flexDirection={"column"}
            >
              <Typography mt={2} fontWeight={FontWeight.BOLD} color={"red"}>
                Your Change Request is in Progress
              </Typography>
              <Typography mt={2} fontWeight={FontWeight.BOLD}>
                Your can procceed once with request is addressed
              </Typography>
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems={"center"}
              flexDirection={"column"}
              sx={{ pt: 2, mt: { xs: 2, md: 4 } }}
            >
              <RequestChange
                applicationId={applicationId}
                onSuccess={() => {
                  const payload = {
                    applicationId: applicationId,
                  };

                  execute(payload, handleResponse);
                }}
              />
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={() => {
                  const payload = {
                    applicationId: applicationId,
                  };
                  executeAcceptQuotation(payload, handleAccpetResponse);
                }}
              >
                Accept and Procced
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Quotation;
