import {
  Box,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  adjustmentCheckBox,
  auditCalculation,
  auditDuration,
} from "../../constants";
import { Field } from "formik";
import {
  FontWeight,
  INPUTFIELDTYPE,
  TextFieldType,
} from "../../../../core/types";
import CommonGrid from "../../../onBoarding/Components/common/CommonGrid";
import { memo } from "react";
import TextInputLight from "../../../../core/components/Input/TextInputLight";

const ManDayCalculation = ({
  companyDetail,
  handleChange,
  handleBlur,
  values,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const auditCalculationFieldText = memo(
    ({ field, form, name, value, onChange, ...props }: any) => {
      return (
        <Box>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "#000",
                fontFamily: "Arial",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000",
                  borderWidth: 1,
                  borderRadius: 2,
                },
              },
              "& .MuiInputLabel-outlined": {
                color: "#2e2e2e",
              },
              width: "100%",
            }}
            name={name}
            value={value}
            onChange={onChange}
            {...field}
            {...props}
          />
        </Box>
      );
    }
  );

  const calculation = (value: any) => {
    let total = 0;
    if (value?.manPowerCalculation) {
      const { manPowerCalculation } = value;

      const TD = manPowerCalculation[0]?.auditManDays
        ? manPowerCalculation[0]?.auditManDays
        : 0;
      const TH = manPowerCalculation[1]?.auditManDays
        ? manPowerCalculation[1]?.auditManDays
        : 0;
      const TMS = manPowerCalculation[2]?.auditManDays
        ? manPowerCalculation[2]?.auditManDays
        : 0;
      const TFTE = manPowerCalculation[3]?.auditManDays
        ? manPowerCalculation[3]?.auditManDays
        : 0;

      const TASV = manPowerCalculation[4]?.auditManDays
        ? manPowerCalculation[4]?.auditManDays
        : 0;

      const TP = manPowerCalculation[5]?.auditManDays
        ? manPowerCalculation[5]?.auditManDays
        : 0;
      const TR = manPowerCalculation[6]?.auditManDays
        ? manPowerCalculation[6]?.auditManDays
        : 0;
      const TT = manPowerCalculation[7]?.auditManDays
        ? manPowerCalculation[7]?.auditManDays
        : 0;

      const THA = manPowerCalculation[8]?.auditManDays
        ? manPowerCalculation[8]?.auditManDays
        : 0;

      total =
        Number(TD) +
        Number(TH) +
        Number(TMS) +
        Number(TFTE) +
        Number(THA) +
        Number(TP) +
        Number(TR);

      updateAuditCalculations(total);

      return total;
    } else {
      updateAuditCalculations(total);
      return total;
    }
  };

  const updateAuditCalculations = (total: any) => {
    auditCalculation.forEach((item, index) => {
      switch (index) {
        case 0:
          item.total = total;
          break;
        case 1:
          item.total = total / 3;
          break;
        case 2:
          item.total = (total / 3) * (2 / 3);
          break;
        case 3:
          item.total = total + 2 * (total / 3);
          break;
      }
    });
  };

  return (
    <Box>
      <TableContainer sx={{ mb: 4 }}>
        <Table
          sx={{
            width: "100%",
            mb: 2,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Audit Time Factors</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Audit Man Days</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auditDuration.map((row: any, index: any) => {
              return (
                <TableRow key={index}>
                  <TableCell>{row.auditTimeFactors}</TableCell>
                  <TableCell>
                    <Field
                      key={index}
                      name={`manPowerCalculation[${index}].details`}
                      as={auditCalculationFieldText}
                      onChange={handleChange}
                      value={values?.manPowerCalculation[index]?.details}
                    />
                  </TableCell>
                  <TableCell>
                    <Field
                      key={index}
                      name={`manPowerCalculation[${index}].auditManDays`}
                      as={auditCalculationFieldText}
                      onChange={handleChange}
                      value={values?.manPowerCalculation[index]?.auditManDays}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display={"flex"} justifyContent={"flex-end"} mb={4}>
        <Typography fontWeight={FontWeight.BOLD} fontSize={16}>
          Total Audit Duration: {calculation(values)} days
        </Typography>
      </Box>
      <Typography fontSize={isMobile ? 16 : 18} fontWeight={FontWeight.BOLD}>
        No. of Product variants and No. of HACCP studies shall be correlated. If
        the no. of product variants is more than the no. of HACCP studies,
        relevant clarification shall be sought from the applicant.
      </Typography>
      <TableContainer sx={{ mb: 4 }}>
        <Table
          sx={{
            width: "100%",
            mb: 2,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Audit Type</TableCell>
              <TableCell>Formula</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auditCalculation.map((row: any, index: any) => {
              return (
                <TableRow key={index}>
                  <TableCell>{row.auditType}</TableCell>
                  <TableCell>{row.formula}</TableCell>
                  <TableCell>
                    <TableCell>
                      {row.total ? row.total?.toFixed(2) : row.total}
                    </TableCell>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography fontWeight={FontWeight.BOLD}>
        Adjustment in Time Duration
      </Typography>
      <Box display={"flex"} alignItems={"center"}>
        <Typography>
          Any time adjustment made in Total Audit Duration
        </Typography>
        <Box width={400}>
          <RadioGroup name="radio" value={values.radio} onChange={handleChange}>
            <CommonGrid
              handleChange={handleChange}
              value={values}
              paddingTop={0.5}
              onBlur={handleBlur}
              list={adjustmentCheckBox}
              filedType={INPUTFIELDTYPE.RADIO}
              noOfRowStyle={16}
            />
          </RadioGroup>
        </Box>
      </Box>
      <TextInputLight
        type={TextFieldType.TEXT}
        value={values["timeAdjustmentModeInInitialCertificationAuditTime"]}
        name={"timeAdjustmentModeInInitialCertificationAuditTime"}
        label={"Time Adjustment made in Initial Certification Audit Time"}
        onChange={handleChange}
        textFieldHeigt={60}
      />
      <TextInputLight
        type={TextFieldType.TEXT}
        value={values["reasonOfAdjusmentOfTime"]}
        name={"reasonOfAdjusmentOfTime"}
        label={"Reasons for adjustment in Time"}
        onChange={handleChange}
        multiline
        row={2}
      />
    </Box>
  );
};

export default ManDayCalculation;
