import { Box, Checkbox, Link, Typography } from "@mui/material";
import { FC, useState } from "react";
import ScrollDialog from "../TermsDialog";

interface TermConditionsCheck {
  fistText?: string;
  linkText?: string;
  secondText?: string;
  link?: any;
  name?: string;
  onChange?: any;
  termsText?: any;
}

const TermsConditionsCheck: FC<TermConditionsCheck> = ({
  fistText = "I agree to the",
  linkText = "link",
  secondText = "as set out by the user agreement",
  name,
  onChange,
  termsText = "",
}) => {
  const [open, setOpen] = useState<any>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box display={"flex"} alignItems={"center"} p={2}>
      <ScrollDialog
        open={open}
        handleClose={handleClose}
        title={linkText}
        dialogContent={termsText}
      />
      <Checkbox
        name={name}
        onChange={(event: any) => {
          onChange(event.target.checked);
        }}
      />

      <Box>
        <Typography fontSize={15} sx={{ color: "#000" }}>
          {fistText}{" "}
          <Link
            sx={{ color: "#2196F3", textDecoration: "none" }}
            onClick={handleClickOpen}
          >
            {linkText}
          </Link>{" "}
          {secondText}
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsConditionsCheck;
