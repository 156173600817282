import { Box, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

interface SummaryChartProps {
  data: {
    total: number;
    active: number;
    completed: number;
    upcoming: number;
    drafts: number;
    inprogress: number;
    quoted: number;
    paymentDoneBy: number;
  };
}

const SummaryChart = ({ data }: SummaryChartProps) => {
  const chartData = {
    labels: [
      'Total Applications',
      'Active Applications',
      'Completed Audits',
      'Upcoming Audits',
      'Drafts Created',
      'In Progress Audits',
      'Quotes Created',
      'Total Payment Done'
    ],
    datasets: [
      {
        label: 'Summary Data',
        data: [
          data.total,
          data.active,
          data.completed,
          data.upcoming,
          data.drafts,
          data.inprogress,
          data.quoted,
          data.paymentDoneBy
        ],
        backgroundColor: '#3f51b5',
        borderRadius: 5,
      }
    ],
  };

  return (
    <Box sx={{ width: '100%', height: 400 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Summary Overview
      </Typography>
      <Bar data={chartData} options={{ maintainAspectRatio: false }} />
    </Box>
  );
};

export default SummaryChart;
