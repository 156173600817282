import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { FontWeight } from "../../../../core/types";
import useAsyncRequest from "../../../../networking/useAsyncRequest";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuditorList } from "../../../../store/AuditorList/auditorListService";
import Loader from "../../../../core/components/Loader";
import { capitalize } from "../../../../core/utils";
import { USERTYPE } from "../../../../core/constants";
import { USERUPDATE } from "../../../../router/config";
import { adminUserInitialValue } from "../../../users/constants";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F4F9",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function createData(name: string, email: any, role: any, designation: any) {
  return { name, email, role, designation };
}

export default function AuditorListTable({
  trigger,
  role = USERTYPE.AUDITOR,
}: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState<any[]>([]);
  const [responseData, setResponseData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { execute, loading } = useAsyncRequest(getAuditorList);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      setResponseData(data);
      let list: any = [];
      const user = data.filter((item: any) => item.role === role);
      user.map((item: any) => {
        list.push(
          createData(
            item.firstName + " " + item.lastName,
            item.email,
            capitalize(item.role),
            item.designation ? capitalize(item.designation) : "-"
          )
        );
      });
      setData(list);
    }
  };

  const filteredData = data.filter(
    (row: any) =>
      row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.designation.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    execute("", handleResponse);
  }, [trigger, location]);

  const renderRowText = (label: any) => {
    return (
      <Typography
        variant="body1"
        color={"#202224"}
      >
        {label}
      </Typography>
    );
  };

  const headingText = (label: any) => {
    return (
      <Typography variant="body1" fontWeight={FontWeight.MEDIUM}>
        {label}
      </Typography>
    );
  };

  return (
    <Box p={2} borderRadius={5} bgcolor={"white"}>
      <Loader open={loading} />
      <TextField
        label="Search"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>{headingText("Name")}</StyledTableCell>
              <StyledTableCell>{headingText("Email")}</StyledTableCell>
              <StyledTableCell>{headingText("Role")}</StyledTableCell>
              <StyledTableCell>{headingText("Designation")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData &&
              filteredData.map((row: any, index: any) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => {
                    adminUserInitialValue.firstName = row.name?.split(" ")[0];
                    adminUserInitialValue.lastName = row.name?.split(" ")[1];
                    adminUserInitialValue.email = row.email;
                    adminUserInitialValue.role = row.role;
                    adminUserInitialValue.designation = row.designation;
                    navigate(USERUPDATE, {
                      state: { user: row },
                    });
                  }}
                >
                  <TableCell component="th" scope="row" width={"35%"}>
                    {renderRowText(row.name)}
                  </TableCell>
                  <TableCell width={"35%"}>
                    {renderRowText(row.email)}
                  </TableCell>
                  <TableCell>{renderRowText(row.role)}</TableCell>
                  <TableCell>{renderRowText(row.designation)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
