import { Box, Grid, Typography } from "@mui/material";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import {
  getStageCheckListByApplication,
} from "../../../store/AuditorFlow/auditorListService";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontWeight } from "../../../core/types";
import Loader from "../../../core/components/Loader";
import TextWithLabel from "../../../core/components/TextWithLabel";
import DashboardContainer from "../../../core/components/DashboardContainer";

const StageCheckList = () => {
  const location = useLocation();
  const { data } = location.state;
  const [responseData, setResponseData] = useState<any>();
  const [applicationDocList, setApplicationDocCheckList] = useState<any>([]);
  const [stageOneAuditList, setStageOneAuditCheckList] = useState<any>([]);
  const [auditorRecommendation, setAuditorRecommendation] = useState<any>([]);

  const { applicationId } = data;

  const { execute, loading } = useAsyncRequest(getStageCheckListByApplication);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      const { requiredListId } = data;
      const {
        stageOneAuditCheckList,
        applicationDocumentsCheckList,
        auditorRecommedation,
      } = requiredListId;
      setApplicationDocCheckList(applicationDocumentsCheckList[0]?.list);
      setStageOneAuditCheckList(stageOneAuditCheckList[0]?.list);
      setAuditorRecommendation(auditorRecommedation);
      setResponseData(requiredListId);
    } else {
      setResponseData(null);
    }
  };

  useEffect(() => {
    const payload = {
      applicationId: applicationId,
    };
    execute(payload, handleResponse);
  }, [applicationId]);

  const LabelList = (list: any) => {
    return (
      <Box>
        {list?.map((item: any, index: any) => (
          <Box>
            {item === null ? (
              <></>
            ) : (
              <Box
                display={"flex"}
                alignContent={"center"}
                alignItems={"center"}
                justifyContent={"space-between"}
                pt={1}
                pb={1}
              >
                <Box>
                  <Typography
                    fontSize={18}
                    p={1}
                    fontWeight={FontWeight.BOLD}
                    key={`${index}`}
                  >
                    {item?.label}
                  </Typography>
                  <Typography
                    fontWeight={FontWeight.MEDIUM}
                    alignSelf={"center"}
                    alignContent={"center"}
                    pl={1}
                  >
                    Remarks: {item.remarks}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor:
                      item?.value === "pass"
                        ? "#54A93F"
                        : item?.value === "fail"
                        ? "red"
                        : "grey",
                    height: 35,
                    width: 100,
                    padding: 1,
                    borderRadius: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Typography
                    alignSelf={"center"}
                    alignContent={"center"}
                    pl={2}
                    pr={2}
                    color={"white"}
                  >
                    {item?.value.toUpperCase()}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  const audiorRecommendationList =() => {
    return (
      <Box>
        <div>
          {auditorRecommendation?.map((item: any, index: any) =>
            Object.keys(item).map((key) => {
              const { value, label } = item[key];
              return (
                <Box
                  display={"flex"}
                  alignContent={"center"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  pt={1}
                  pb={1}
                >
                  <Typography
                    fontSize={16}
                    p={1}
                    fontWeight={FontWeight.MEDIUM}
                    key={`${index}`}
                  >
                    {label}
                  </Typography>
                  <Box
                  sx={{
                    backgroundColor: value ? "#54A93F" : "red",
                    height: 35,
                    width: 100,
                    padding: 1,
                    borderRadius: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Typography
                    alignSelf={"center"}
                    alignContent={"center"}
                    pl={2}
                    pr={2}
                    color={"white"}
                  >
                    {value ? "YES" : "NO"}
                  </Typography>
                </Box>
                
                </Box>
              );
            })
          )}
        </div>
      </Box>
    );
  };

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && <TextWithLabel title={title} subTitle={subTitle != 'undefined' ? subTitle : "-" } />}
      </Grid>
    );
  };

  return (
    <Box>
      <Loader open={loading} />
      {responseData ? (
        <Box>
        <DashboardContainer>
          <Grid
            container
            spacing={4}
            alignItems={"baseline"}
            columns={{ xs: 2, sm: 12, md: 16 }}
            sx={{
              paddingBottom: 2,
            }}
          >
            {detailField("Audit Mode", responseData?.auditMode)}
            {detailField(
              "Document Remarks",
              responseData?.applicationDocumentsCheckListRemarks !
            )}
            {detailField(
              "Document Follow Up",
              responseData?.applicationDocumentsCheckListFollowUpComments
            )}
          </Grid>
        </DashboardContainer>
          <Box>
            {applicationDocList && (
              <Typography variant="h6" fontWeight={FontWeight.BOLD} pt={2}>
                Application Documents
              </Typography>
            )}
            <DashboardContainer>
            {applicationDocList && LabelList(applicationDocList)}
            <Box>
              <Grid
                container
                spacing={4}
                alignItems={"baseline"}
                columns={{ xs: 2, sm: 12, md: 16 }}
                sx={{
                  paddingBottom: 2,
                }}
              >
                {detailField(
                  "Documnet Remarks",
                  responseData?.applicationDocumentsCheckListRemarks
                )}
                {detailField(
                  "Document Follow Up",
                  responseData?.applicationDocumentsCheckListFollowUpComments
                )}
              </Grid>
            </Box>
            </DashboardContainer>
          </Box>
          {stageOneAuditList && (
            <Typography variant="h6" fontWeight={FontWeight.BOLD} pt={2}>
              Stage One Audit CheckList
            </Typography>
          )}
           <DashboardContainer>
            <Box pt={1}>
              {stageOneAuditList && LabelList(stageOneAuditList)}
              <Grid
                container
                spacing={4}
                alignItems={"baseline"}
                columns={{ xs: 2, sm: 12, md: 16 }}
                sx={{
                  paddingBottom: 2,
                }}
              >
                {detailField(
                  "Follow Up Remarks of Stage Two",
                  responseData?.followUpRemarksToStageTwoAudit
                )}
              </Grid>
            </Box>
           </DashboardContainer>
          {auditorRecommendation && (
            <Typography variant="h6" fontWeight={FontWeight.BOLD} pt={2}>
              Auditor Recommendation
            </Typography>
          )}
           <DashboardContainer>
          {auditorRecommendation &&
            audiorRecommendationList()}
            </DashboardContainer>
        </Box>
      ) : (
        <DashboardContainer>
          <Box>
            <Typography>CheckList not available yet</Typography>
          </Box>
        </DashboardContainer>
      )}
    </Box>
  );
};

export default StageCheckList;
