import { apiClient } from "../../networking/apiClient";
import {
  CHANGEPASSWORD,
  DELETEUSER,
  GETAPPLICATIONDETAILSBYMAIL,
  GOOGLELOGIN,
  LOGIN,
  REGISTRATION,
  RESETPASSWORD,
  VERIFYEMAIL,
} from "../../networking/endpoints";

export const userLogin = async (payload: any) => {
  try {
    const response = await apiClient.post(LOGIN, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const userRegistration = async (payload: any) => {
  try {
    const response = await apiClient.post(REGISTRATION, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const googleLogin = async (payload: any) => {
  try {
    const response = await apiClient.post(GOOGLELOGIN, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (payload: any) => {
  try {
    const response = await apiClient.post(RESETPASSWORD, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePassword = async (payload: any) => {
  try {
    const response = await apiClient.post(CHANGEPASSWORD, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (payload: any) => {
  try {
    const response = await apiClient.post(DELETEUSER, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyEmail = async (payload: any) => {
  try {
    const response = await apiClient.post(VERIFYEMAIL, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getApplicationbyEmail = async (payload: any) => {
  try {
    const response = await apiClient.post(GETAPPLICATIONDETAILSBYMAIL, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};
