import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { FontWeight } from "../../../core/types";
import { useLocation, useNavigate } from "react-router-dom";
import { assessmentDetails, assessmentInitialValues } from "../constants";
import { Formik } from "formik";
import { findingsSchema, stageOneAuditCheckListA } from "../../../core/validations/formSchema";
import CommonGrid from "../../onBoarding/Components/common/CommonGrid";
import { useState } from "react";
import FindingsComponent from "../components/FindingsComponent";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { auditAssessment } from "../../../store/AuditorFlow/auditorListService";
import Loader from "../../../core/components/Loader";
import { AUDITFINDIGS } from "../../../router/config";

const AssessmentFindings = () => {  
  const navigate = useNavigate();
  const location = useLocation();
  const { data: info } = location.state;

  const { applicationId } = info;

  const { execute, loading } = useAsyncRequest(auditAssessment);

  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      navigate(AUDITFINDIGS, {
        state: { data: info },
      });
    }
  };

  const onFileSelected = (data: any) => {
    setSelectedFiles([...selectedFiles, data]);
  };
  

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading} />
        <Formik
          initialValues={assessmentInitialValues}
          validationSchema={findingsSchema}
          onSubmit={(value: any) => {
            const formData = new FormData();
            formData.append("applicationId", applicationId);
            Object.entries(value).forEach(([key, value]: any) => {
              const newValue = JSON.stringify(value);
              formData.append(key, newValue);
            });
            selectedFiles.map((item: any) => {
              for (let i = 0; i < item.file.length; i++) {
                formData.append(`file[${item.index}]`, item.file[i]);
              }
            });
            execute(formData, handleResponse);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Typography
                variant="h6"
                fontWeight={FontWeight.BOLD}
                pl={2}
                pt={3}
              >
                RECORD OF ASSESSMENT FINDINGS (RAF)
              </Typography>
              <CommonGrid
                handleChange={handleChange}
                value={values}
                onBlur={handleBlur}
                paddingTop={{ xs: 2, md: 2 }}
                error={errors}
                list={assessmentDetails}
                touched={touched}
              />
              <Typography
                variant="h6"
                fontWeight={FontWeight.BOLD}
                pl={2}
                pt={3}
              >
                Details of Finding
              </Typography>
              <FindingsComponent
                setFieldValue={setFieldValue}
                fieldName={"detailOfFindings"}
                onFileSelected={onFileSelected}
              />
              <Box
                pt={4}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"85%"}
              >
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AssessmentFindings;
