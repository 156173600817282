import {
  Alert,
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { FontWeight } from "../../../core/types";
import { useLocation, useNavigate } from "react-router-dom";
import { dummySignature, logo } from "../../../core/Images";
import { handleDownloadPdf } from "../../../core/utils";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { createDraft } from "../../../store/Application/applicationService";
import Loader from "../../../core/components/Loader";
import { useState } from "react";
import { useSelector } from "react-redux";
import { USERTYPE } from "../../../core/constants";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AuditDarft = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: any) => state.auth.user);
  const { role } = user;
  const { data } = location.state;
  const [alertOpen, setAlertOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const handleOpenAlert = () => setAlertOpen(true);
  const handleCloseAlert = () => setAlertOpen(false);

  const { companyDetail, applicationId } = data;

  const { nameOfCompany, address } = companyDetail;

  const { execute, loading } = useAsyncRequest(createDraft);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      setSuccess(true);
      setMessage("Draft Created Succesfully");
      handleCloseAlert();
    } else {
      setSuccess(false);
      handleCloseAlert();
      setMessage("Something Went Wrong");
    }
  };

  const commonText = (
    text = "name",
    fontSize = 16,
    pt = 2,
    fontWeight = FontWeight.BOLD
  ) => {
    return (
      <Typography
        sx={{ textAlign: "center" }}
        fontSize={fontSize}
        fontWeight={fontWeight}
        pl={1}
        pt={pt}
      >
        {text}
      </Typography>
    );
  };

  const createDraftCall = () => {
    const payload = {
      applicationId: applicationId,
    };

    execute(payload, handleResponse);
  };

  const confirmAlert = () => {
    return (
      <Modal
        open={alertOpen}
        onClose={handleCloseAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please confirm, are you sure want to create draft
          </Typography>
          <Box
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
          >
            <Button onClick={() => handleCloseAlert()}>No</Button>
            <Button
              onClick={() => {
                createDraftCall();
              }}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading} />
        {confirmAlert()}
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"baseline"}
        >
          <IconButton onClick={() => handleDownloadPdf("page-content")}>
            <Typography mr={1}>Download</Typography>
            <FileDownloadRoundedIcon />
          </IconButton>
        </Box>

        <Box id="page-content" sx={{ padding: 5, backgroundColor: "white" }}>
          <Box
            pt={7}
            pl={2}
            pr={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            width={"100%"}
            sx={{ backgroundColor: "white" }}
          >
            <Box
              sx={{
                backgroundImage: `url(${logo})`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `200px 50px`,
                height: 50,
                width: 200,
              }}
            />
            <Typography
              fontSize={24}
              fontWeight={FontWeight.BOLD}
              pl={2}
              pt={3}
            >
              Halal Registration Certificate Draft
            </Typography>
            {commonText(
              "This is to certify that the establishment described below has been inspected and assessed by Halal Point and found to comply with Halal requirement.",
              16,
              4
            )}
            {commonText(nameOfCompany, 20, 4)}
            {commonText(address, 18, 4, FontWeight.REGULAR)}
            {commonText("Scope : Food Products", 18, 4, FontWeight.REGULAR)}
            {commonText(
              "Registration No. : JUG/4765/OOOTTEH/XXX",
              18,
              4,
              FontWeight.REGULAR
            )}
            {commonText("Issued on : 24-04-2024", 18, 4, FontWeight.REGULAR)}
            {commonText(
              "Validity : 24-04-2024 to 23-05-2025",
              18,
              4,
              FontWeight.REGULAR
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              pt: 5,
              pl: 5,
              backgroundColor: "white",
            }}
          >
            {commonText("Authorized Signatory", 18, 4, FontWeight.BOLD)}
            <Box
              sx={{
                backgroundImage: `url(${dummySignature})`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `150px 150px`,
                height: 150,
                width: 150,
              }}
            />
            {commonText("Rajeev KR", 18, 0, FontWeight.BOLD)}
            {commonText("Secretary", 14, 0, FontWeight.REGULAR)}
          </Box>
        </Box>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          {role === USERTYPE.DECISION && (
            <Box>
              {(success || data.activeStatus !== "Draft Created") && (
                <Button
                  onClick={() => {
                    handleOpenAlert();
                  }}
                  variant="contained"
                >
                  Create Draft
                </Button>
              )}
            </Box>
          )}
        </Box>
        {message && (
          <Alert severity={success ? "success" : "error"}>{message}</Alert>
        )}
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AuditDarft;
