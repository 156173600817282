import { useLocation, useNavigate } from "react-router-dom";
import DashboardContainer from "../../core/components/DashboardContainer";
import AdminLayout from "../dashboard/components/AdminLayout";
import { Box, Avatar, Typography, Button, Modal, Alert } from "@mui/material";
import { TextFieldType } from "../../core/types";
import { Formik } from "formik";
import CommonGridOnBoarding from "../onBoarding/Components/common/CommonGridOnboarding";
import { admimUserDetails, adminUserInitialValue } from "./constants";
import {
  adminUserSchema,
  passwordResetSchema,
} from "../../core/validations/formSchema";
import { useEffect, useState } from "react";

import TextInputLight from "../../core/components/Input/TextInputLight";
import useAsyncRequest from "../../networking/useAsyncRequest";
import { deleteUser, resetPassword } from "../../store/Auth/authService";
import Loader from "../../core/components/Loader";
import { useSelector } from "react-redux";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UserUpdate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = location.state;

  const loggedUser = useSelector((state: any) => state.auth.user);

  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenAlert = () => setAlertOpen(true);
  const handleCloseAlert = () => setAlertOpen(false);

  const { name, email, role, designation } = user;

  useEffect(() => {
    adminUserInitialValue.firstName = name?.split(" ")[0];
    adminUserInitialValue.lastName = name?.split(" ")[1];
    adminUserInitialValue.email = email;
    adminUserInitialValue.role = role;
    adminUserInitialValue.designation = designation;
  }, [location]);

  const { execute, loading: loading } = useAsyncRequest(resetPassword);

  const { execute: deleteUserExe, loading: deleteLoading } =
    useAsyncRequest(deleteUser);

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status === 200) {
      handleClose();
      setMessage("Password Changed Successfully");
      setSuccess(true);
    } else {
      setMessage("Something went wrong");
      setSuccess(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
      if (isDelete) {
        navigate(-1);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [message, success]);

  const handleDelete = (res: any) => {
    const { status } = res;
    if (status === 200) {
      handleCloseAlert();
      setMessage("User Deleted Successfully");
      setSuccess(true);
      setIsDelete(true);
    } else {
      setMessage("Something went wrong");
      setSuccess(false);
    }
  };

  const deleteUserCall = () => {
    const payload = {
      email: email,
    };
    deleteUserExe(payload, handleDelete);
  };

  const confirmAlert = () => {
    return (
      <Modal
        open={alertOpen}
        onClose={handleCloseAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure want to delete this user
          </Typography>
          <Box
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
          >
            <Button onClick={() => handleCloseAlert()}>No</Button>
            <Button onClick={() => deleteUserCall()}>Yes</Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const resetPasswordModel = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reset Password
          </Typography>
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={passwordResetSchema}
            onSubmit={(value: any) => {
              const payload = {
                email: email,
                password: value.password,
              };

              execute(payload, handleResponse);

              console.log(value);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextInputLight
                  type={TextFieldType.PASSWORD}
                  name={"password"}
                  label={"Password"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  textFieldHeigt={60}
                  error={
                    errors["password"] &&
                    touched["password"] &&
                    errors["password"]
                      ? true
                      : false
                  }
                  helperText={
                    errors["password"] &&
                    touched["password"] &&
                    errors["password"]
                  }
                />
                <TextInputLight
                  type={TextFieldType.PASSWORD}
                  name={"confirmPassword"}
                  label={"Confirm Password"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  textFieldHeigt={60}
                  error={
                    errors["confirmPassword"] &&
                    touched["confirmPassword"] &&
                    errors["confirmPassword"]
                      ? true
                      : false
                  }
                  helperText={
                    errors["confirmPassword"] &&
                    touched["confirmPassword"] &&
                    errors["confirmPassword"]
                  }
                />
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignItems={"flex-end"}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mr: 2 }}
                  >
                    Reset Password
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    );
  };

  return (
    <AdminLayout>
      <DashboardContainer>
        <Loader open={loading || deleteLoading} />
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          flexDirection={"row"}
          sx={{ borderRadius: 5, boxShadow: 2, p: 2 }}
        >
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Avatar
              alt={name}
              src="/static/images/avatar/2.jpg"
              style={{ color: "black" }}
              sx={{
                height: 100,
                width: 100,
                fontSize: 50,
              }}
            />
            <Typography fontSize={20} pt={2}>
              {role}
            </Typography>
            <Formik
              initialValues={adminUserInitialValue}
              validationSchema={adminUserSchema}
              onSubmit={(value: any) => {
                console.log(value);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                dirty,
              }) => (
                <form onSubmit={handleSubmit}>
                  <CommonGridOnBoarding
                    handleChange={handleChange}
                    value={values}
                    onBlur={handleBlur}
                    error={errors}
                    list={admimUserDetails}
                    touched={touched}
                    paddingTop={{ xs: 2, md: 4 }}
                  />
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    flexDirection={"row"}
                  >
                    {/* <Button
                      type="submit"
                      variant="contained"
                      disabled={!dirty}
                      sx={{ mt: 2, mr: 2 }}
                    >
                      Update Profile
                    </Button> */}
                    {loggedUser.email !== email && (
                      <Button
                        variant="contained"
                        sx={{ mt: 2, backgroundColor: "red" }}
                        onClick={() => handleOpenAlert()}
                      >
                        Delete
                      </Button>
                    )}
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        <Box>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            flexDirection={"row"}
            mt={2}
          >
            <Button onClick={handleOpen}>Reset Password</Button>
            {resetPasswordModel()}
            {confirmAlert()}
          </Box>
          {message && (
            <Alert severity={success ? "success" : "error"}>{message}</Alert>
          )}
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default UserUpdate;
