import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import AdminLayout from "../dashboard/components/AdminLayout";
import DashboardContainer from "../../core/components/DashboardContainer";
import SuccessIcon from "../../Icons/SuccessIcon";
import { FontWeight } from "../../core/types";

const ReviewStatus = () => {
  const location = useLocation();
  const { data } = location.state;
  const { companyDetail } = data;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <AdminLayout>
      <DashboardContainer>
        <Box
          display="flex"
          justifyContent={isMobile ? "center" : "start"}
          alignItems={"center"}
          flexDirection={"column"}
          sx={{ mt: { xs: 2, md: 20 } }}
          height={"65vh"}
        >
          <SuccessIcon />
          <Typography mt={2} variant="h6" fontWeight={FontWeight.BOLD}>
            Application Review Completed
          </Typography>
          <Typography mt={2} variant="h6" textAlign={isMobile ? 'center' :'start'} fontWeight={FontWeight.BOLD}>
            {companyDetail.nameOfCompany} needs to complete payment to proceed
            futher
          </Typography>
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};
export default ReviewStatus;
