import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Loader from "../../../core/components/Loader";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { requestChange } from "../../../store/Oboarding/quotationServices";
import { Grid } from "@mui/material";
import DropdownMenu from "../../../core/components/DropDownMenu";
import { useState } from "react";
import { getEmailByName } from "../../../core/utils";
import { updatedAuditor } from "../../../store/Application/applicationService";

const UpdateAuditor = ({ applicationId, onSuccess, auditorData }: any) => {
  const [open, setOpen] = useState(false);
  const [leadAuditor, setLeadAuditor] = useState("");
  const [auditor, setAuditor] = useState("");

  const { execute, loading } = useAsyncRequest(updatedAuditor);

  const handleReponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      handleClose();
      onSuccess();
    }
  };

  const handleSubmit = () => {
    const payload = {
      applicationId: applicationId,
      leadAuditorEmail: getEmailByName(leadAuditor, auditorData),
      auditorEmail: getEmailByName(auditor, auditorData),
      auditor: auditor,
      leadAuditor: leadAuditor,
      reason: "updated auditor",
    };
    execute(payload, handleReponse);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const auditorDropDown = (
    label: any,
    menuLabel: any,
    handleChange?: any,
    value?: any,
    name?: any
  ) => {
    return (
      <Grid item xs={2} sm={4} md={4}>
        <DropdownMenu
          label={label}
          menuLabel={menuLabel}
          handleChange={(val: any) => handleChange(val)}
          value={value}
          width={"90%"}
          name={name}
          option={auditorData}
        />
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Update Auditor
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Loader open={loading} />
        <DialogTitle id="alert-dialog-title">
          {"Please Select Auditor"}
        </DialogTitle>
        <DialogContent sx={{ p: 5 }}>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to accept?
          </DialogContentText>
          {auditorDropDown(
            "Lead Auditor",
            "Select Auditor",
            (val: any) => {
              setLeadAuditor(val.target.value);
            },
            leadAuditor,
            "leadAuditor"
          )}
          {auditorDropDown(
            "Auditor",
            "Select Auditor",
            (val: any) => {
              setAuditor(val.target.value);
            },
            auditor,
            "auditor"
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default UpdateAuditor;
