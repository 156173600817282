import { configureStore } from "@reduxjs/toolkit";

import AuthSlice from "./Auth/authSlice";
import onBoardingSlice from "./Oboarding/onBoardingSlice";
import adminSlice from "./AdminFlow/adminSlice";

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    onBoarding: onBoardingSlice,
    admin: adminSlice,
  },
});
