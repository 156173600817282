import { Box, Button, Typography } from "@mui/material";
import AdminLayout from "../dashboard/components/AdminLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { FontWeight } from "../../core/types";
import DashboardContainer from "../../core/components/DashboardContainer";
import { CREATEQUOTATION } from "../../router/config";
import CompanyDetailComponent from "../../core/components/CompanyDetailComponent";

const ApplicationDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state;
  const { companyDetail } = data;

  return (
    <AdminLayout>
      <Typography variant="h4" fontWeight={FontWeight.BOLD}>
        {companyDetail.nameOfCompany}
      </Typography>
      <DashboardContainer>
        <CompanyDetailComponent data={data} />
        <Box
          pt={10}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate(CREATEQUOTATION, {
                state: { data: data },
              });
            }}
          >
            Create Quotation
          </Button>
        </Box>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default ApplicationDetail;
