import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import LabelDownloadComponent from "../../../../core/components/LabelDownloadComponent";
import { useNavigate } from "react-router-dom";
import {
  AUDITDRAFT,
  AUDITREPORT,
  ISSUECERTIFICATE,
  VIEWAUDITFINDINGS,
} from "../../../../router/config";

const AuditFindingsComponent = (data: any) => {
  const navigate = useNavigate();

  const { data: prosData } = data;
  const { application, companyDetail } = prosData;
  const { applicationId, draftStatus, activeStatus, userEmail } = application;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Grid
        container
        spacing={ isMobile ? 1 : 4}
        alignItems={"baseline"}
        columns={{ xs: 2, sm: 12, md: 16 }}
        sx={{
          paddingTop: 2,
        }}
      >
        <Grid item xs={4} sm={4} md={4}>
          <LabelDownloadComponent
           bgColor='#abd5e9'
            label={"Audit Findings"}
            onClick={() =>
              navigate(VIEWAUDITFINDINGS, {
                state: { data: { applicationId } },
              })
            }
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          <LabelDownloadComponent
          bgColor='#a6c0f1'
            label={"Summary Report"}
            onClick={() =>
              navigate(AUDITREPORT, {
                state: { data: data },
              })
            }
          />
        </Grid>
        <Grid item xs={2} sm={4} md={4}>
          {draftStatus == "CREATED" && (
            <LabelDownloadComponent
            bgColor='#a7aef0'
              label={"Draft"}
              onClick={() =>
                navigate(AUDITDRAFT, {
                  state: { data: { companyDetail, applicationId } },
                })
              }
            />
          )}
        </Grid>

        <Grid item xs={2} sm={4} md={4}>
          {activeStatus == "Certificate Issued" && (
            <LabelDownloadComponent
            bgColor='#abc4ee'
              label={"Certificate"}
              onClick={() =>
                navigate(ISSUECERTIFICATE, {
                  state: { data: { applicationId, userEmail } },
                })
              }
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuditFindingsComponent;
