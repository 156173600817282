import { Box, Typography } from "@mui/material";
import { createData } from "../../../core/utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import {
  getActiveApplication,
  getActiveApplicationAuditor,
} from "../../../store/Dashboard/dashboardService";
import { getCertificatesByUser } from "../../../store/Application/applicationService";
import DashboardContainer from "../../../core/components/DashboardContainer";
import AdminLayout from "../../dashboard/components/AdminLayout";
import { FontWeight } from "../../../core/types";

const UserCertificates = () => {
  const user = useSelector((state: any) => state.auth.user);
  const [data, setData] = useState<any[]>([]);
  const [responseData, setResponseData] = useState<any[]>([]);

  const { role, email } = user;

  const { execute, loading } = useAsyncRequest(getCertificatesByUser);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      setResponseData(data);
    }
  };

  useEffect(() => {
    const paylaod = {
      userEmail: email,
    };
    execute(paylaod, handleResponse);
  }, []);

  return (
    <AdminLayout>
      <DashboardContainer>
        <Typography variant="h6" fontWeight={FontWeight.BOLD}>Your Certificates</Typography>
        {responseData.map((items: any) => {
          return (
            <Box
              sx={{
                mt : 2,
                boxShadow: 5,
                width: "100%",
                borderRadius: 5,
                p : 4
              }}
            >
              <Typography fontWeight={FontWeight.BOLD} pb={1} color={'green'}>
                Status : Active
              </Typography>
              <Typography fontWeight={FontWeight.BOLD} pb={1}>
                Application No : {items.application.applicationNo}
              </Typography>
              <Typography fontWeight={FontWeight.BOLD} pb={1}>
                Type : {items.application.certification.toUpperCase()}
              </Typography>
              <Typography fontWeight={FontWeight.BOLD} pb={1}>
                Scope : {items.application.requiredCertifications.join(', ')}
              </Typography>
              <Typography fontWeight={FontWeight.BOLD} pb={2}>
                Certificate No : {items.certificateNo}
              </Typography>
              <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
              <Typography fontWeight={FontWeight.BOLD} pb={1} pr={2}>
                Effective Date : {items.effectiveDate}
              </Typography>
              <Typography fontWeight={FontWeight.BOLD} pb={1}>
                Expiry Date : {items.expiryDate}
              </Typography>
              </Box>
            </Box>
          );
        })}
      </DashboardContainer>
    </AdminLayout>
  );
};

export default UserCertificates;
