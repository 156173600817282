import React, { useState } from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  Collapse,
  Typography,
  Box,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";

interface DrawerItem {
  label: string;
  nav: string;
  icon?: JSX.Element;
  children?: {
    segment: string;
    title: string;
    icon: JSX.Element;
    nav: string;
  }[];
}
const DrawerMenu = ({
  drawerList,
  selectedIndex,
}: {
  drawerList: DrawerItem[];
  selectedIndex: number;
}) => {
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState<number | null>(null);

  const handleSubmenuClick = (index: number) => {
    setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
  };

  return (
    drawerList && (
      <List>
        {drawerList.map((item: DrawerItem, index: number) => (
          <React.Fragment key={index}>
            <ListItemButton
              sx={{ padding: 2 }}
              component={item.children ? "div" : Link}
              to={item.children ? undefined : item.nav}
              onClick={() => item.children && handleSubmenuClick(index)}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ListItemIcon
                    sx={{
                      color: selectedIndex === index ? "#4880FF" : "#72777a",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <Typography
                    fontWeight="medium"
                    fontSize={14}
                    color={selectedIndex === index ? "#4880FF" : "#72777a"}
                  >
                    {item.label}
                  </Typography>
                </Box>
                {item.children &&
                  (openSubmenuIndex === index ? (
                    <ExpandLess style={{ color: "gray" }} />
                  ) : (
                    <ExpandMore style={{ color: "gray" }} />
                  ))}
              </Box>
            </ListItemButton>
            {item.children && (
              <Collapse
                in={openSubmenuIndex === index}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {item.children.map((child, childIndex) => (
                    <ListItemButton
                      key={childIndex}
                      sx={{ paddingLeft: 4 }}
                      component={Link}
                      to={child.nav}
                    >
                      <ListItemIcon sx={{ color: "#72777a" }}>
                        {child.icon}
                      </ListItemIcon>
                      <Typography fontSize={14} color="#72777a">
                        {child.title}
                      </Typography>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    )
  );
};

export default DrawerMenu;
