import * as React from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import Dot from "../../../../core/components/Dots/Index";
import { createData } from "../../../../core/utils";
import { USERTYPE } from "../../../../core/constants";
import {
  REQUIREDCHECKLIST,
  REQUIREDLIST,
  AUDITFINDIGS,
  STAGEONECHECKLIST,
  DECISIONAPPLICATIONVIEW,
  REVIEWSTATUS,
  AUDITPLANING,
  ADMINREVIEW,
  APPLICATIONSTATUS,
  APPLICATIONDETAIL,
  CREATEQUOTATION,
} from "../../../../router/config";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  TextField,
  Grid,
  Autocomplete,
  IconButton,
  useMediaQuery,
  Paper,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandCircleDownRounded from "@mui/icons-material/ExpandCircleDownRounded";

const rows = [createData("Name", "Camera Lens", 40, 2, 40570)];

const headCells = [
  {
    id: "tracking_no",
    align: "left",
    disablePadding: false,
    label: "Date",
  },
  {
    id: "name",
    align: "left",
    disablePadding: true,
    label: "ApplicationId",
  },
  {
    id: "fat",
    align: "lrft",
    disablePadding: false,
    label: "Company Name",
  },
  {
    id: "carbs",
    align: "left",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "protein",
    align: "right",
    disablePadding: false,
    label: "Type",
  },
  {
    id: "protein",
    align: "right",
    disablePadding: false,
    label: "view",
  },
];

function OrderTableHead({ order, orderBy }: any) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function OrderStatus({ status, sx }: any) {
  let color;
  let title;

  switch (status) {
    case "Audit In Progress":
      color = "warning";
      title = "In Progress";
      break;
    case "Audit Completed":
      color = "success";
      title = "Completed";
      break;
    case "Audit Planned":
      color = "primary";
      title = "Planned";
      break;
    case "Payment Pending":
      color = "primary";
      title = "Payment Pending";
      break;
    case "Payment Done":
      color = "success";
      title = "Payment Done";
      break;
    case "Quotation Created":
      color = "success";
      title = "Quotation Created";
      break;
    case "Quotation Accepted":
      color = "success";
      title = "Quotation Accepted";
      break;
    case "Quotation Updated":
      color = "success";
      title = "Quotation Updated";
      break;
    case "Quotation Change Request":
      color = "error";
      title = "Quotation Change Request";
      break;
    case "Audit Rejected":
      color = "error";
      title = "Audit Change Request";
      break;
    case "Audit Accepted":
      color = "success";
      title = "Audit Accepted";
      break;
    case "Audit Review":
      color = "primary";
      title = "Audit Review";
      break;
    case "Audit Updated":
      color = "primary";
      title = "Audit Updated";
      break;
    case "Certificate Issued":
      color = "success";
      title = "Certificate Issued";
      break;
    case "Active":
      color = "primary";
      title = "Active";
      break;
    case "Draft Created":
      color = "primary";
      title = "Draft Created";
      break;
    case "Decision Accepted":
      color = "success";
      title = "Decision Accepted";
      break;
    case 2:
      color = "error";
      title = "Rejected";
      break;
    default:
      color = "primary";
      title = "None";
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
}

type RowData = {
  applicationDate: string;
  applicationId: string;
  name: string;
  status: string;
  type: string;
  tracking_no: string;
};

type Props = {
  data: RowData[];
  rows: RowData[];
  role: string;
  resData: RowData[];
  navigate: (path: string, options?: any) => void;
};

const statusOptions = [
  "Payment Pending",
  "Payment Done",
  "Quotation Accepted",
  "Quotation Created",
  "Quotation Change Request",
  "Quotation Updated",
  "Audit Planned",
  "Audit Rejected",
  "Audit Updated",
  "Audit Accepted",
  "Active",
  "Audit Completed",
  "Audit In Progress",
];

const typeOptions = ["FRESH", "RENEWAL"];

export default function OrderTable({ data, resData, role }: any) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterDate, setFilterDate] = useState<string | null>(null);
  const [filterStatus, setFilterStatus] = useState<string[]>([]);
  const [filterType, setFilterType] = useState<string[]>([]);
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width:600px)");
  const order = "asc";
  const orderBy = "tracking_no";

  const filteredData = (data ? data : rows)
    .filter(
      (row: any) =>
        row.applicationId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(
      (row: any) =>
        (!filterDate || row.applicationDate === filterDate) &&
        (filterStatus.length === 0 || filterStatus.includes(row.status)) &&
        (filterType.length === 0 || filterType.includes(row.type.toUpperCase()))
    );

  const onClick = (application: any) => {
    const { activeStatus, auditStatus } = application;
    if (role == USERTYPE.AUDITOR) {
      if (activeStatus === "Certificate Issued") {
        navigate(DECISIONAPPLICATIONVIEW, {
          state: { data: application },
        });
      } else {
        switch (auditStatus) {
          case "CHECKLIST":
            navigate(REQUIREDCHECKLIST, {
              state: {
                data: application,
              },
            });
            break;
          case "REQUIREDLIST":
            navigate(REQUIREDLIST, {
              state: { data: application },
            });
            break;
          case "OPENCLOSINGINSTRUCTIONS":
            navigate(REQUIREDLIST, {
              state: { data: application },
            });
            break;
          case "AUDITFINDINGS":
            navigate(AUDITFINDIGS, {
              state: { data: application },
            });
            break;
          default:
            navigate(STAGEONECHECKLIST, {
              state: { data: application },
            });
        }
      }
    } else if (role === USERTYPE.DECISION || role === USERTYPE.USER) {
      navigate(DECISIONAPPLICATIONVIEW, {
        state: { data: application },
      });
    } else {
      switch (activeStatus) {
        case "Payment Pending":
          navigate(REVIEWSTATUS, {
            state: { data: application },
          });
          break;
        case "Payment Done":
          navigate(AUDITPLANING, {
            state: { data: application },
          });
          break;
        case "Quotation Accepted":
          navigate(ADMINREVIEW, {
            state: { data: application },
          });
          break;

        case "Quotation Created":
          navigate(CREATEQUOTATION, {
            state: { data: application },
          });
          break;
        case "Quotation Change Request":
          navigate(CREATEQUOTATION, {
            state: { data: application },
          });
          break;
        case "Quotation Updated":
          navigate(CREATEQUOTATION, {
            state: { data: application },
          });
          break;
        case "Audit Planned":
          navigate(APPLICATIONSTATUS);
          break;
        case "Audit Rejected":
          navigate(AUDITPLANING, {
            state: { data: application },
          });
          break;
        case "Audit Updated":
          navigate(AUDITPLANING, {
            state: { data: application },
          });
          break;
        case "Audit Accepted":
          navigate(AUDITPLANING, {
            state: { data: application },
          });
          break;
        case "Active":
          navigate(APPLICATIONDETAIL, {
            state: { data: application },
          });
          break;
        case "Audit Completed":
          navigate(APPLICATIONSTATUS, {
            state: { data: application },
          });
          break;
        case "Audit In Progress":
          navigate(APPLICATIONSTATUS, {
            state: { data: application },
          });
          break;
        case "Certificate Issued":
          navigate(DECISIONAPPLICATIONVIEW, {
            state: { data: application },
          });
          break;
        case "Decision Accepted":
          navigate(DECISIONAPPLICATIONVIEW, {
            state: { data: application },
          });
          break;
        default:
          navigate(APPLICATIONDETAIL, {
            state: {
              data: application,
            },
          });
      }
    }
  };

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
        mb={2}
      >
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Search by Application ID or Name"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Filter by Date"
            type="date"
            variant="outlined"
            value={filterDate || ""}
            onChange={(e) => setFilterDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            multiple
            options={statusOptions}
            value={filterStatus}
            onChange={(event, newValue) => setFilterStatus(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Filter by Status"
              />
            )}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            multiple
            options={typeOptions}
            value={filterType}
            onChange={(event, newValue) => setFilterType(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Filter by Type"
              />
            )}
            fullWidth
          />
        </Grid>
      </Grid>
      {!isMobile ? (
        <TableContainer
          sx={{
            width: "100%",
            overflowX: "auto",
            position: "relative",
            display: "block",
            maxWidth: "100%",
            "& td, & th": { whiteSpace: "nowrap" },
          }}
        >
          <Table aria-labelledby="tableTitle">
            <OrderTableHead order={order} orderBy={orderBy} />
            <TableBody>
              {filteredData.map((row: any, index: any) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const application = resData.find(
                  (item: any) => item.applicationNo === row.applicationId
                );
                return (
                  <TableRow
                    hover
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    tabIndex={-1}
                    key={row.tracking_no}
                  >
                    <TableCell
                      width={150}
                      component="th"
                      id={labelId}
                      scope="row"
                      onClick={() => {
                        onClick(application);
                      }}
                    >
                      {row.applicationDate}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        onClick(application);
                      }}
                      width={200}
                    >
                      {row.applicationId}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        onClick(application);
                      }}
                      width={250}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        onClick(application);
                      }}
                      align="right"
                      width={100}
                    >
                      <OrderStatus status={row.status} />
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        onClick(application);
                      }}
                      align="right"
                      width={80}
                    >
                      {row.type.toUpperCase()}
                    </TableCell>
                    <TableCell align="right" width={60}>
                      <IconButton
                        onClick={() => {
                          navigate(DECISIONAPPLICATIONVIEW, {
                            state: { data: application },
                          });
                        }}
                      >
                        <RemoveRedEyeRoundedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ width: "100%" }} mb={15}>
          {filteredData.map((row: any, index: any) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            const application = resData.find(
              (item: any) => item.applicationNo === row.applicationId
            );

            return (
              <Accordion key={row.tracking_no}>
                <AccordionSummary
                  expandIcon={<ExpandCircleDownRounded htmlColor="#2196f3" />}
                  aria-controls={`panel-content-${index}`}
                  id={`panel-header-${index}`}
                  sx={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    ml={2}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                        Application ID
                      </Typography>
                      <Typography variant="body2">
                        {row.applicationId}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                        Status
                      </Typography>
                      <OrderStatus status={row.status} />
                    </Box>
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: "8px",
                      marginLeft: "2.3rem",
                    }}
                  >
                    {/* Company Name */}
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      onClick={() => {
                        onClick(application);
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                        Company Name
                      </Typography>
                      <Typography variant="body2">{row.name}</Typography>
                    </Box>
                    {/* Date */}
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      onClick={() => {
                        onClick(application);
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                        Date
                      </Typography>
                      <Typography variant="body2">
                        {row.applicationDate}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      onClick={() => {
                        onClick(application);
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                        Type
                      </Typography>
                      <Typography variant="body2">
                        <Typography variant="body2">{row.type}</Typography>
                      </Typography>
                    </Box>

                    {/* View Icon */}
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      onClick={() => {
                        onClick(application);
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                        View
                      </Typography>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(DECISIONAPPLICATIONVIEW, {
                            state: { data: application },
                          });
                        }}
                      >
                        <RemoveRedEyeRoundedIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      )}
    </Box>
  );
}

OrderTableHead.propTypes = { order: PropTypes.any, orderBy: PropTypes.string };

OrderStatus.propTypes = { status: PropTypes.number };
