// import AdminLayout from "../../dashboard/components/AdminLayout";
// import DashboardContainer from "../../../core/components/DashboardContainer";
// import useAsyncRequest from "../../../networking/useAsyncRequest";
// import { getAuditPlainByApplicationId } from "../../../store/AuditorFlow/auditorListService";
// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { Box, Typography } from "@mui/material";
// import { FontWeight } from "../../../core/types";
// import Loader from "../../../core/components/Loader";

// const AuditSchedule = () => {
//   const details = useSelector((state: any) => state.onBoarding.details);

//   const { application } = details;
//   const { applicationId } = application;

//   const [responseData, setResponseData] = useState<any>([]);
//   const { execute, loading } = useAsyncRequest(getAuditPlainByApplicationId);

//   const handleResponse = (res: any) => {
//     const { status, data } = res;
//     if (status == 200) {
//       setResponseData(data);
//     }
//   };
//   useEffect(() => {
//     const payload = {
//       applicationId: applicationId,
//     };
//     execute(payload, handleResponse);
//   }, []);

//   const scheduleComponent = (title: any, subtitle: any) => {
//     return (
//       <Box
//         sx={{
//           m: 2,
//           height: 80,
//           widows: "100%",
//           borderRadius: 5,
//           boxShadow: 3,
//           display: "flex",
//           flexDirection: "column",
//           // alignItems: "center",
//           justifyContent: "center",
//           paddingLeft: '1rem'
//         }}
//       >
//         <Typography variant="h6" fontWeight={FontWeight.BOLD}>
//           {title}
//         </Typography>
//         <Typography variant="h6">{subtitle}</Typography>
//       </Box>
//     );
//   };

//   return (
//     <AdminLayout>
//       <DashboardContainer>
//         <Loader open={loading} />
//         <Box display={"flex"}>
//           <Typography variant="h5" fontWeight={FontWeight.BOLD}>
//             Audit Schedule
//           </Typography>
//         </Box>
//         <Box>
//           {scheduleComponent(responseData.openMeetingDate, "Opening Meeting")}
//           {scheduleComponent(
//             responseData.dayOneFirstDate,
//             responseData.dayOneFirst
//           )}
//           {scheduleComponent(
//             responseData.dayOneSecondDate,
//             responseData.dayOneSecond
//           )}
//           <Typography
//             variant="h5"
//             fontWeight={FontWeight.BOLD}
//             textAlign={"center"}
//             p={2}
//             color={"green"}
//           >
//             Day One
//           </Typography>
//           {scheduleComponent(
//             responseData.dayOneBeforeBreakDate,
//             "QMS Auditor :" +
//               responseData.dayOneBeforeBreakQmsAuditor +
//               " || " +
//               "Technical Auditor :" +
//               responseData.dayOneBeforeBreakTechnicalAuditor
//           )}
//           {scheduleComponent(responseData.dayOnebreakDateTime, "Day One Break")}
//           {scheduleComponent(
//             responseData.dayOneAfterBreakDate,
//             "QMS Auditor :" +
//               responseData.dayOneAfterBreakQmsAuditor +
//               " || " +
//               "Technical Auditor :" +
//               responseData.dayOneAfterBreakTechnicalAuditor
//           )}
//           <Typography
//             variant="h5"
//             fontWeight={FontWeight.BOLD}
//             textAlign={"center"}
//             p={2}
//             color={"green"}
//           >
//             Day Two
//           </Typography>
//           {scheduleComponent(
//             responseData.dayTwoBeforeBreakDate,
//             "QMS Auditor :" +
//               responseData.dayTwoBeforeBreakQmsAuditor +
//               " || " +
//               "Technical Auditor :" +
//               responseData.DayTwoBeforeBreakTechnicalAuditor
//           )}
//           {scheduleComponent(responseData.dayTwobreakDateTime, "Day Two Break")}
//           {scheduleComponent(
//             responseData.dayTwoAfterBreakDate,
//             "QMS Auditor :" +
//               responseData.dayTwoAfterBreakQmsAuditor +
//               " || " +
//               "Technical Auditor :" +
//               responseData.dayTwoAfterBreakTechnicalAuditor
//           )}
//           {scheduleComponent(
//             responseData.dayTwobreakDateTime,
//             responseData.dayTwoBeforeClosing
//           )}
//           {scheduleComponent(
//             responseData.closingMeetingDate,
//             "Closing Meeting"
//           )}
//         </Box>
//       </DashboardContainer>
//     </AdminLayout>
//   );
// };

// export default AuditSchedule;

import AdminLayout from "../../dashboard/components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { getAuditPlainByApplicationId } from "../../../store/AuditorFlow/auditorListService";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Loader from "../../../core/components/Loader";
import AuditPlan from "../../decisionFlow/AuditPlan";
import AuditorAccept from "./components/AcceptAuditor";
import AuditorReject from "./components/RejectAuditor";

const AuditSchedule = () => {
  const details = useSelector((state: any) => state.onBoarding.details);
  const { application } = details;
  const { applicationId } = application;

  const [responseData, setResponseData] = useState<any>([]);
  const { execute, loading } = useAsyncRequest(getAuditPlainByApplicationId);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      setResponseData(data);
    } else {
      setResponseData([]);
    }
  };

  useEffect(() => {
    const payload = {
      applicationId: applicationId,
    };
    execute(payload, handleResponse);
  }, []);

  // const scheduleComponent = (title: any, subtitle: any) => (
  //   <Box
  //     sx={{
  //       m: 2,
  //       p: 2,
  //       borderRadius: 2,
  //       boxShadow: 1,
  //       backgroundColor: "#f9f9f9",
  //       width: "100%",
  //     }}
  //   >
  //     <Typography variant="h6" fontWeight="bold">
  //       {title}
  //     </Typography>
  //     <Typography variant="subtitle1">{subtitle}</Typography>
  //   </Box>
  // );

  if (loading) {
    return <Loader open={loading} />;
  }

  return (
    <AdminLayout>
      <DashboardContainer>
        <AuditPlan data={application} />
        {/* {responseData.length !== 0 ? (
          <Box>
            <Box textAlign="center" mb={4}>
              <Typography variant="h4" fontWeight="bold">
                Audit Schedule
              </Typography>
            </Box>

            <Box>
              {scheduleComponent(
                responseData.openMeetingDate,
                "Opening Meeting"
              )}
              <Divider sx={{ my: 3 }} />

              <Typography
                variant="h5"
                fontWeight="bold"
                textAlign="center"
                color="green"
                mb={2}
              >
                Day One
              </Typography>
              {scheduleComponent(
                responseData.dayOneFirstDate,
                responseData.dayOneFirst
              )}
              {scheduleComponent(
                responseData.dayOneSecondDate,
                responseData.dayOneSecond
              )}
              {scheduleComponent(
                responseData.dayOneBeforeBreakDate,
                <>
                  QMS Auditor: {responseData.dayOneBeforeBreakQmsAuditor}
                  <br />
                  Technical Auditor:{" "}
                  {responseData.dayOneBeforeBreakTechnicalAuditor}
                </>
              )}
              {scheduleComponent(
                responseData.dayOnebreakDateTime,
                "Day One Break"
              )}
              {scheduleComponent(
                responseData.dayOneAfterBreakDate,
                `QMS Auditor: ${responseData.dayOneAfterBreakQmsAuditor} || Technical Auditor: ${responseData.dayOneAfterBreakTechnicalAuditor}`
              )}
              <Divider sx={{ my: 3 }} />

              <Typography
                variant="h5"
                fontWeight="bold"
                textAlign="center"
                color="green"
                mb={2}
              >
                Day Two
              </Typography>
              {scheduleComponent(
                responseData.dayTwoBeforeBreakDate,
                `QMS Auditor: ${responseData.dayTwoBeforeBreakQmsAuditor} || Technical Auditor: ${responseData.DayTwoBeforeBreakTechnicalAuditor}`
              )}
              {scheduleComponent(
                responseData.dayTwobreakDateTime,
                "Day Two Break"
              )}
              {scheduleComponent(
                responseData.dayTwoAfterBreakDate,
                `QMS Auditor: ${responseData.dayTwoAfterBreakQmsAuditor} || Technical Auditor: ${responseData.dayTwoAfterBreakTechnicalAuditor}`
              )}
              {scheduleComponent(
                responseData.dayTwobreakDateTime,
                responseData.dayTwoBeforeClosing
              )}
              {scheduleComponent(
                responseData.closingMeetingDate,
                "Closing Meeting"
              )}
            </Box>
          </Box>
        ) : (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Typography fontWeight={FontWeight.BOLD}>
              Audit Not Planned Yet
            </Typography>
          </Box>
        )} */}
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AuditSchedule;
