import {
  Typography,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  Radio,
} from "@mui/material";
import { FontWeight, INPUTFIELDTYPE } from "../../../../core/types";
import { FC } from "react";
import { CompanyDetail } from "../../Constants";
import TextInputLight from "../../../../core/components/Input/TextInputLight";
import DateTimePicker from "../../../../core/components/DatePicker";

interface CommonGridProps {
  heading?: string;
  handleChange: any;
  value: any;
  onBlur?: any;
  error?: any;
  touched?: any;
  list: CompanyDetail[];
  filedType?: any;
  paddingTop?: any;
  noOfRowStyle?: number;
  onChangeCertificationList?: any;
}

const CommonGrid: FC<CommonGridProps> = ({
  heading,
  handleChange,
  onChangeCertificationList,
  value,
  error,
  onBlur,
  list,
  touched,
  filedType = INPUTFIELDTYPE.TEXT,
  paddingTop = { xs: 2, md: 3 },
  noOfRowStyle = 12,
}) => {
  return (
    <Box>
      {heading && (
        <Typography
          variant="h6"
          fontWeight={FontWeight.BOLD}
          sx={{ pl: 5, pt: 2 }}
        >
          {heading}
        </Typography>
      )}
      <Grid
        container
        spacing={4}
        columns={{ xs: 2, sm: noOfRowStyle, md: noOfRowStyle }}
        sx={{
          paddingTop: paddingTop,
          paddingLeft: { xs: 2, md: 4 },
          paddingRight: { xs: 2, md: 4 },
          paddingBottom: 1,
          alignItems: "end",
        }}
      >
        {list.map((item: any, index) => {
          const { name, type, label, multiline, row } = item;
          return (
            <Grid item xs={2} sm={4} md={4} key={index}>
              {filedType === INPUTFIELDTYPE.TEXT ? (
                <TextInputLight
                  type={type}
                  name={name}
                  label={label}
                  onChange={handleChange}
                  multiline={multiline}
                  row={row}
                  value={value[name]}
                  onBlur={onBlur}
                  textFieldHeigt={40}
                  error={
                    error[name] && touched[name] && error[name] ? true : false
                  }
                  helperText={error[name] && touched[name] && error[name]}
                />
              ) : filedType === INPUTFIELDTYPE.CHECKBOX ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={label}
                      onChange={onChangeCertificationList}
                      checked={value[label]}
                      onBlur={onBlur}
                    />
                  }
                  label={item.label}
                />
              ) : filedType === INPUTFIELDTYPE.RADIO ? (
                <FormControlLabel
                  value={name}
                  control={<Radio color="primary" />}
                  label={label}
                />
              ) : filedType === INPUTFIELDTYPE.COMPANYDETAILCHECK ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={name}
                      onChange={handleChange}
                      checked={value[name]}
                      onBlur={onBlur}
                    />
                  }
                  label={item.label}
                />
              ) : filedType === INPUTFIELDTYPE.DATE ? (
                <Box mb={3}>
                  <DateTimePicker
                    label={item.label}
                    name={name}
                    onChange={handleChange}
                  />
                </Box>
              ) : null}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default CommonGrid;
