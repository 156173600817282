import Box from "@mui/material/Box";
import SignUpForm from "./Components/SignUpForm";
import BaseAuthTemplate from "../../../core/components/CommonTemplate/BaseAuthTemplate";
import ImageContainer from "../Common/ImageContainer";

const SignUpPage = () => {
  return (
    <BaseAuthTemplate>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <SignUpForm />
        <ImageContainer />
      </Box>
    </BaseAuthTemplate>
  );
};

export default SignUpPage;
