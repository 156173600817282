import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import InsertInvitationRoundedIcon from "@mui/icons-material/InsertInvitationRounded";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { getApplicationbyEmail } from "../../../store/Auth/authService";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../core/components/Loader";
import AuditFindingsComponent from "./component/AuditFindings";
import AdminLayout from "../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import TextWithLabel from "../../../core/components/TextWithLabel";
import WelcomeComponent from "./component/WelcomeComponent";
import ApplicationOverview from "./component/ApplicationOverview";
import MobileBottomNavbar from "../../../core/components/MobileBottomNavbar";

const UserApplicationProgressDashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [progress, setProgress] = useState(10);
  const [responseData, setResponseData] = useState<any>({});
  const [applicationData, setApplicationData] = useState<any>({});
  const [stepOne, setStepOne] = useState<any>(1);
  const [stepTwo, setStepTwo] = useState<any>();
  const [stepThree, setStepThree] = useState<any>(false);

  const user = useSelector((state: any) => state.auth.user);
  const findings = useSelector((state: any) => state.admin.userAuditFinings);

  const { execute: getApplication, loading: loadingApplication } =
    useAsyncRequest(getApplicationbyEmail);

  const handelApplicationResponse = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      const { application } = data;
      setApplicationData(application);
      setResponseData(data);
      const { activeStatus, applicationId } = application;

      const payload = {
        applicationId: applicationId,
      };

      switch (activeStatus) {
        case "Audit Planned":
          setStepOne(2);
          setProgress(50);
          break;
        case "Audit Review":
          setStepOne(3);
          setStepTwo(0);
          setProgress(60);
          break;
        case "Audit In Progress":
          setStepOne(3);
          setStepTwo(1);
          setProgress(70);
          break;
        case "Audit Completed":
          setProgress(80);
          setStepOne(3);
          setStepTwo(3);
          break;
        case "Draft Created":
          setProgress(90);
          setStepOne(3);
          setStepTwo(3);
          break;
        case "Certificate Issued":
          setProgress(100);
          setStepOne(3);
          setStepTwo(3);
          setStepThree(true);
          break;
      }
    }
  };

  useEffect(() => {
    const payload = {
      userEmail: user.email,
    };
    getApplication(payload, handelApplicationResponse);
  }, [findings]);

  const summaryContainer = (label: any, onClick?: any) => {
    return (
      <Box
        onClick={onClick}
        mt={1}
        mb={1}
        mr={2}
        p={1}
        borderRadius={1}
        sx={{ backgroundColor: "#0f1322" }}
      >
        <Typography color={"white"}>{label}</Typography>
      </Box>
    );
  };

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && <TextWithLabel title={title} subTitle={subTitle} />}
      </Grid>
    );
  };

  return (
    <>
    <AdminLayout>
        <>
          <Loader open={loadingApplication} />
          <WelcomeComponent user={user}/>
          {responseData?.rejectReason && <Typography>Application is reject for the reason : {responseData?.rejectReason}</Typography>}
          <Box
            p={3}
            width={"100%"}
            borderRadius={3}
            boxShadow={2}
            sx={{ backgroundColor: "white" }}
          >
            <ApplicationOverview progress={progress} applicationData={applicationData} responseData={responseData}/>
            {stepTwo == 3 && <AuditFindingsComponent data={responseData} />}
            {stepThree ? (
              <Box>
                <Grid
                  container
                  spacing={ isMobile ? 1 : 4}
                  alignItems={"baseline"}
                  columns={{ xs: 2, sm: 12, md: 16 }}
                  sx={{
                    paddingTop: 2,
                  }}
                >
                  {detailField(
                    "Status",
                    responseData.application?.activeStatus
                  )}
                  {detailField(
                    "Certificate Issued Date",
                    responseData.application?.certificateIssuedDate
                  )}
                  {detailField(
                    "Effective Date",
                    responseData.application?.effectiveDate
                  )}
                  {detailField(
                    "Expiry Date",
                    responseData.application?.expiryDate
                  )}
                </Grid>
              </Box>
            ) : (
              <Box>
               
              </Box>
            )}
          </Box>
        
          <Box
            mt={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexDirection={!isMobile ? 'row' : "column"}
          >
            <Box
              p={2}
              mt={2}
              width={"100%"}
              borderRadius={3}
              boxShadow={2}
              alignItems={"center"}
              justifyContent={"center"}
              m={1}
              sx={{ backgroundColor: "white" }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <NotificationsActiveRoundedIcon sx={{ height: 60, width: 60 }} />
                <Box ml={2}>
                  <Typography>Notifications</Typography>
                  <Typography>0</Typography>
                </Box>
              </Box>
            </Box>
            {/* <Box
              p={2}
              mt={2}
              width={"100%"}
              borderRadius={3}
              boxShadow={2}
              alignItems={"center"}
              justifyContent={"center"}
              m={1}
              sx={{ backgroundColor: "white" }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <CardMembershipRoundedIcon sx={{ height: 60, width: 60 }} />
                <Box ml={2}>
                  <Typography>Apply for a new Halal Certificate</Typography>
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate(COMPANYDETAILS);
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </Box> */}
  
            <Box
              p={2}
              mt={2}
              width={"100%"}
              borderRadius={3}
              boxShadow={2}
              alignItems={"center"}
              justifyContent={"center"}
              m={1}
              sx={{ backgroundColor: "white" }}
              mb={isMobile ? 12 : 0}
            >
              <Typography></Typography>
              <Box display={"flex"} alignItems={"center"}>
                <InsertInvitationRoundedIcon sx={{ height: 60, width: 60 }} />
                <Box ml={2}>
                  <Typography>Upcoming Events</Typography>
                  <Typography>
                    On Site Audit schedule on {new Date(applicationData.auditDate).toLocaleDateString()}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          </>   
    </AdminLayout>
   </>
  );
};

export default UserApplicationProgressDashboard;
