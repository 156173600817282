import {
  Box,
  Checkbox,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { TextFieldType } from "../../../../core/types";
import TextInputLight from "../../../../core/components/Input/TextInputLight";

const ParticularsField = ({
  rows = [1, 2, 4],
  setFieldValue,
  onBlur,
  fieldName = "Name",
}: any) => {
  const [selected, setSelected] = useState<any>({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCheckChange =
    (name: string, option: string, index: any) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = {
        value: event.target.checked ? option : "",
      };
      setSelected((prev: any) => ({
        ...prev,
        [name]: newValue,
      }));
      setFieldValue(`${fieldName}.${[index]}.value`, newValue.value);
    };

  useEffect(() => {}, [onBlur]);

  return (
    <FormControl component="fieldset">
      <Table
        sx={{
          minWidth: isMobile ? "100%" : 650,
          width: "100%",
          [`& .${tableCellClasses.root}`]: {
            borderBottom: "none",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" width={1000}></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any, rowIndex: number) => (
            <TableRow key={rowIndex}>
              <TableCell component="th" scope="row" width={"100%"}>
                <TextInputLight
                  type={TextFieldType.TEXT}
                  name={fieldName}
                  label=""
                  onChange={(val: any) => {
                    setFieldValue(
                      `${fieldName}.${[rowIndex]}.item`,
                      val.target.value
                    );
                  }}
                  onBlur={onBlur}
                  multiline={true}
                  width={"100%"}
                  labelHeight={0}
                />
              </TableCell>
              <TableCell align="center">
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  mt={2}
                >
                  <Checkbox
                    checked={selected[row]?.value === "yes"}
                    onChange={handleCheckChange(row, "yes", rowIndex)}
                  />
                  <Typography>Yes</Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  mt={2}
                >
                  <Checkbox
                    checked={selected[row]?.value === "no"}
                    onChange={handleCheckChange(row, "no", rowIndex)}
                  />
                  <Typography>No</Typography>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </FormControl>
  );
};

export default ParticularsField;
