import { Box, Button, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { FontWeight, TextFieldType } from "../../../core/types";
import TextInputTextField from "../../../core/components/Input/TextInputTextField";
import DropdownMenu from "../../../core/components/DropDownMenu";
import UploadField from "../../../core/components/FileComponent/uploadField";
import { gradingOptions } from "../constants";

interface SelectedState {
  [key: string]: string;
}

const FindingsComponent = ({
  setFieldValue,
  name,
  fieldName = "name",
  onFileSelected,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  const [selected, setSelected] = useState<any>([]);
  const [findings, setFinding] = useState<any>([1]);

  const onSelectFile = (file: any) => {
    setSelected([...selected, file]);
  };

  return (
    <Box pl={isMobile ? 0 : 2} pt={1} width={"100%"}>
      {findings.map((item: any, index: any) => {
        return (
          <Box pb={4} pt={2} mt={2} width={"100%"}>
            <Typography pl={isMobile ? 0 : 1} fontWeight={FontWeight.BOLD}>
              No : {index + 1}
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              pt={1}
              pl={isMobile ? 0 : 1}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <DropdownMenu
                label={"GRADING"}
                menuLabel={"Select Grading"}
                handleChange={(val: any) => {
                  setFieldValue(
                    `${fieldName}[${index}].[grading]`,
                    val.target.value
                  );
                }}
                width={350}
                name={name}
                option={gradingOptions}
                type="assessment"
              />
              <TextInputTextField
              mt={isMobile ? 2 : 0}
                size={"large"}
                type={TextFieldType.TEXT}
                name="referenceClause"
                label="Reference Clause"
                onChange={(val: any) => {
                  setFieldValue(
                    `${fieldName}[${index}].[referenceClause]`,
                    val.target.value
                  );
                }}
                labelHeight={0}
                width={350}
              />
              <TextInputTextField
               mt={isMobile ? 4 : 0}
                size={"large"}
                type={TextFieldType.TEXT}
                name="assessementTeamMember"
                label="ASSESSMENT TEAM MEMBER"
                onChange={(val: any) => {
                  setFieldValue(
                    `${fieldName}[${index}].[assessementTeamMember]`,
                    val.target.value
                  );
                }}
                labelHeight={0}
                width={350}
              />
              <Box
                mt={isMobile ? 4 : 2}
                height={50}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <UploadField
                  onSelectFile={(file: any) => {
                    const fileData = {
                      index: index,
                      file: file,
                    };
                    onFileSelected(fileData);
                    onSelectFile(file);
                    setFieldValue(`${fieldName}[${index}].file`, file);
                  }}
                />
              </Box>
            </Box>
            <Box width={"100%"}>
            <TextInputTextField
              type={TextFieldType.TEXT}
              name="remarks"
              label="REMARKS"
              width={"100%"}
              onChange={(val: any) => {
                setFieldValue(
                  `${fieldName}[${index}].[remarks]`,
                  val.target.value
                );
              }}
              multiline={true}
              row={2}
            />
            </Box>
          </Box>
        );
      })}
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"end"}
        pt={2}
      >
        <Button
          onClick={() => {
            setFinding((prev: any) => [...prev, prev.length + 1]);
          }}
        >
          + Add More
        </Button>
      </Box>
    </Box>
  );
};

export default FindingsComponent;
