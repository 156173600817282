import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import DashboardContainer from "../../../core/components/DashboardContainer";
import UniqueVisitorCard from "./components/LieCharts";
import OrderTable from "./components/Recent";
import AdminLayout from "../components/AdminLayout";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { auditorDashboard } from "../../../store/AuditorFlow/auditorListService";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../core/components/Loader";
import { createData } from "../../../core/utils";
import { useTheme } from "@mui/material/styles";

const AuditorDashboard = () => {
  const user = useSelector((state: any) => state.auth.user);
  const { role, email } = user;
  const [responseData, setResponseData] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    execute,
    loading: loading,
    error,
    response,
  } = useAsyncRequest(auditorDashboard);

  const handleRes = (res: any) => {
    const { status, data } = res;
    if (status === 200) {
      const { recentList } = data;
      let list: any = [];
      setResponseData(recentList);
      recentList.map((item: any) => {
        list.push(
          createData(
            item.applicationNo ? item.applicationNo : "-",
            item.companyDetail.nameOfCompany,
            item.createdDate ? item.createdDate : "-",
            item.certification ? item.certification : "-",
            item.activeStatus ? item.activeStatus : "Active"
          )
        );
      });
      setData(list);
    }
  };

  useEffect(() => {
    const payload = {
      email: email,
    };
    execute(payload, handleRes);
  }, []);

  const card = (title: string, subTitle: string) => {
    return (
      <Box
        sx={{
          height: 100,
          width: 250,
          boxShadow: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          backgroundColor: "white",
          borderRadius: 2,
          pl: 2,
        }}
      >
        <Typography>{title}</Typography>
        <Typography>{subTitle}</Typography>
      </Box>
    );
  };

  return (
    <AdminLayout>
      {!isMobile ? (
        <DashboardContainer>
          <Loader open={loading} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
              justifyContent: isMobile ? 'center' : 'space-between',
              fontSize: isMobile ? '1rem' : '0.875rem'
            }}
          >
            <Box sx={{ minWidth: 250 }}> {/* Set a minimum width for each card */}
              {card("Total Assigned Audits", response?.data?.total)}
            </Box>

            <Box sx={{ minWidth: 250 }}>
              {card("Completed Audits", response?.data?.completed)}
            </Box>

            <Box sx={{ minWidth: 250 }}>
              {card("Upcoming Audits", response?.data?.upcomming)}
            </Box>

            <Box sx={{ minWidth: 250 }}>
              {card("In Progress Audits", response?.data?.inprogress)}
            </Box>
          </Box>

          <Grid item xs={12} md={7} lg={8}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography mt={4} mb={4} variant="h6">
                  Recent Applications
                </Typography>
              </Grid>
              <Grid item />
            </Grid>
            <OrderTable data={data} resData={responseData} role={role} />
          </Grid>
        </DashboardContainer>
      ) : (
        <>
          <Loader open={loading} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {card("Total Assigned Audits", response?.data?.total)}
            {card("Completed Audits", response?.data?.completed)}
            {card("Upcoming Audits", response?.data?.upcomming)}
            {card("In Progress Audits", response?.data?.inprogress)}
          </Box>

          <Grid item xs={12} md={7} lg={8}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography mt={4} mb={4} variant="h6">
                  Recent Applications
                </Typography>
              </Grid>
              <Grid item />
            </Grid>
            <OrderTable data={data} resData={responseData} role={role} />
          </Grid>
        </>
      )}

    </AdminLayout>
  );
};

export default AuditorDashboard;
