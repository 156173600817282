import { Box, Typography } from "@mui/material";
import { BillItem } from "../../Constants";
import { FC } from "react";

interface QuotationDetailProps {
  quotationBreakUp: BillItem[];
}

const QuotationDetails: FC<QuotationDetailProps> = ({
  quotationBreakUp = [],
}) => {
  return (
    <Box pl={5} pr={6} pt={4}>
      {quotationBreakUp.map((data) => {
        const { id, item, amount } = data;
        return (
          <Box
            key={id}
            p={1.5}
            display={"flex"}
            flexDirection={"row"}
            width={"100%"}
            justifyContent={"space-between"}
          >
            <Typography width={"80%"} variant={"subtitle2"}>
              {item}
            </Typography>
            <Typography variant={"subtitle2"}>{amount}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default QuotationDetails;
