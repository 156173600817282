import { Box, Typography } from "@mui/material";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { AUDITCHECKLISTVIEW } from "../../../router/config";
import { getrequiredList } from "../../../store/AuditorFlow/auditorListService";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontWeight } from "../../../core/types";
import Loader from "../../../core/components/Loader";

const RequiredCheckList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state;
  const [responseData, setResponseData] = useState<any>();
  const [checklist, setCheckList] = useState<any>([]);
  const [fmshecklist, setfmsCheckList] = useState<any>([]);

  const { applicationId } = data;

  const { execute, loading } = useAsyncRequest(getrequiredList);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      const { requiredListId } = data;
      const { checkList, fmsChecklist } = requiredListId;
      setCheckList(checkList);
      setfmsCheckList(fmsChecklist);
      setResponseData(data);
    } else {
      setResponseData(null);
    }
  };

  useEffect(() => {
    const payload = {
      applicationId: applicationId,
    };
    execute(payload, handleResponse);
  }, [applicationId]);
  const LabelList = (checkList: any, onClickCompleted: any) => {
    return (
      <Box>
        {checkList.map((item: any, index: any) => (
          <Box
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"space-between"}
            pt={1}
            pb={1}
            onClick={() =>
              item.status === "Completed"
                ? onClickCompleted({ item: item, label: item.label })
                : null
            }
          >
            <Typography
              fontSize={18}
              p={1}
              fontWeight={FontWeight.BOLD}
              key={`${index}`}
            >
              {item.label}
            </Typography>
            <Box
              sx={{
                backgroundColor:
                  item.status === "Completed" ? "#54A93F" : "#B3B7B2",
                height: 35,
                width: 100,
                padding: 1,
                borderRadius: 1,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Typography
                alignSelf={"center"}
                alignContent={"center"}
                pl={2}
                pr={2}
                color={"white"}
              >
                {item.status ? item.status : "To Do"}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box>
      <Loader open={loading} />
      {responseData ? (
        <Box>
          <Box pl={1} pt={2}>
            {checklist &&
              LabelList(checklist, (val: any) => {
                navigate(AUDITCHECKLISTVIEW, {
                  state: {
                    data: data,
                    item: val.item,
                    label: val.label,
                  },
                });
              })}
            <Box></Box>
          </Box>
          {fmshecklist && (
            <Typography variant="h6" fontWeight={FontWeight.BOLD} pl={2} pt={3}>
              Halal Audit FSMS Checklist
            </Typography>
          )}
          <Box pl={1} pt={2}>
            {fmshecklist &&
              LabelList(fmshecklist, (val: any) => {
                navigate(AUDITCHECKLISTVIEW, {
                  state: {
                    data: data,
                    item: val.item,
                    label: val.label,
                  },
                });
              })}
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography>Required checkList not available yet</Typography>
        </Box>
      )}
    </Box>
  );
};

export default RequiredCheckList;
