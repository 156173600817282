import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FontWeight } from "../../types";
import { USERTYPE } from "../../constants";

const DropdownMenu = ({
  label,
  menuLabel,
  handleChange,
  value,
  name,
  option,
  width,
  type = USERTYPE.AUDITOR,
}: any) => {
  return (
    <Box sx={{ width: width }}>
      <Typography
        fontWeight={FontWeight.BOLD}
        fontSize={16}
        color={"#929396"}
        mb={1}
      >
        {label}
      </Typography>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="demo-simple-select-outlined-label">
          {menuLabel}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={handleChange}
          label="Select Option"
          name={name}
          IconComponent={ArrowDropDownIcon}
          fullWidth
          style={{ minWidth: "100%", width : '100%' }}
          MenuProps={{
            PaperProps: {
              style: {
                minWidth: "30%",
              },
            },
          }}
        >
          {option &&
            option.map((item: any) => {
              return type === USERTYPE.AUDITOR ? (
                <MenuItem value={item.firstName + " " + item.lastName}>
                  {item.firstName + " " + item.lastName}
                </MenuItem>
              ) : (
                <MenuItem value={item}>{item}</MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropdownMenu;
