import react, { useEffect, useState } from "react";
import {
  Box,
  AppBar,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import BaseAuthTemplate from "../../../core/components/CommonTemplate/BaseAuthTemplate";
import SignUpForm from "../SignUp/Components/SignUpForm";
import { useLocation, useSearchParams } from "react-router-dom";
import SignInForm from "./Components/SigninForm";
import { USERTYPE } from "../../../core/constants";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { verifyEmail } from "../../../store/Auth/authService";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: 1, md: 3 } }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const SignIn = () => {
  const location = useLocation();
  const { type = "user" } = location?.state;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <BaseAuthTemplate>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          //backgroundImage: 'radial-gradient(100.34% 109.3% at 87.65% 15.21%, rgba(34, 170, 226, 0.37) 0%, rgba(244, 195, 69, 0.39) 100%)',
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          position: "relative",
          padding: "7rem 0 7rem 0",
        }}
      >
        <Box
          sx={{
            padding: 5,
            width: isMobile ? "100%" : "30%",
            borderRadius: 5,
            //   boxShadow: `
            //   0 4px 8px rgba(0, 0, 0, 0.2), /* Soft shadow */
            //   0 8px 16px rgba(0, 0, 0, 0.15), /* Medium shadow */
            //   0 12px 24px rgba(0, 0, 0, 0.1) /* Strong shadow */
            // `,
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            backdropFilter: "blur(2px)",
            position: "relative",
            zIndex: 1,
          }}
        >
          <AppBar
            position="static"
            sx={{ boxShadow: "none", backgroundColor: "transparent" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
              sx={{
                backgroundColor: "#FAFAFF",
                border: "2px solid #2196f3",
                borderRadius: 2,
                "& .MuiTabs-indicator": {
                  height: "2px",
                  backgroundColor: "#2196f3",
                },
                "& .MuiTab-root": {
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: isMobile ? "0.9rem" : "1rem",
                  color: "#2196f3",
                  "&.Mui-selected": {
                    color: "#fff",
                    backgroundColor: "#2196f3",
                  },
                },
              }}
            >
              <Tab label="Sign Up" {...a11yProps(0)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <SignUpForm />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Box>
    </BaseAuthTemplate>
  );
};

export default SignIn;
