import AdminLayout from "../components/AdminLayout";
import DashboardContainer from "../../../core/components/DashboardContainer";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import Loader from "../../../core/components/Loader";
import { useEffect, useState } from "react";
import useAsyncRequest from "../../../networking/useAsyncRequest";
import { createData } from "../../../core/utils";
import { adminDashboard } from "../../../store/Dashboard/dashboardService";
import OrderTable from "../AuditorDashboard/components/Recent";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ExpandCircleDownOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import MobileBottomNavbar from "../../../core/components/MobileBottomNavbar";
import SummaryChart from "../components/SummaryChart";


const AdminDashboard = () => {
  const user = useSelector((state: any) => state.auth.user);
  const { role } = user;
  const [recent, setRecent] = useState<any[]>([]);
  const [responseData, setResponseData] = useState<any>({});
  const [data, setData] = useState<any[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { execute, loading: loading } = useAsyncRequest(adminDashboard);

  const handleResponse = (res: any) => {
    const { status, data } = res;
    if (status == 200) {
      const { recentList } = data;
      let list: any = [];
      setRecent(recentList);
      setResponseData(data);
      recentList.map((item: any) => {
        list.push(
          createData(
            item.applicationNo ? item.applicationNo : "-",
            item.companyDetail.nameOfCompany,
            item.createdDate ? item.createdDate : "-",
            item.certification ? item.certification : "-",
            item.activeStatus ? item.activeStatus : "Active"
          )
        );
      });
      setData(list);
    }
  };

  useEffect(() => {
    execute("", handleResponse);
  }, []);

  const card = (title: string, subTitle: string, borderColor: string) => {
    return (
      <Box
        sx={{
          height: 100,
          width: 250,
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          p: 2,
          backgroundColor: "white",
          borderRadius: 1,
          color: "#1d1d1f",
          mx: 1,
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.05)",
            "& .icon": {
              transform: "translateX(5px)", 
        },
      },
        }}
      >
        <>
          <Typography fontWeight={600}>{title}</Typography>
          <Typography fontWeight={700}>{subTitle}</Typography>
        </>
      </Box>
    );
  };

  return (
    <>
    <AdminLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <Box
          sx={{
            flex: 6,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <DashboardContainer>
            <Typography variant="h6">Status</Typography>
            <Accordion style={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={
                  <ExpandCircleDownOutlined style={{ color: "#2196f3" }} />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 2,
                    mb: 4,
                  }}
                >
                  {card("Total Applications Received",responseData?.total,"#fff")}
                  {card("Active applications", responseData?.active, "#fff")}
                  {card("Completed audits", responseData?.completed, "#fff")}
                  {card("Upcoming audits", responseData?.upcoming, "#fff")}
                  {card("Draft created", responseData?.drafts, "#fff")}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 2,
                    mb: 4,
                  }}
                >
                  {card("In-progress audits", responseData?.inprogress, "#fff")}
                  {card("Quote created", responseData?.quoted, "#fff")}
                  {card("Total payments done",responseData?.paymentDoneBy, "#fff")}
                </Box>
              </AccordionDetails>
            </Accordion>
          </DashboardContainer>
        </Box>

        {/* <Box
          sx={{
            flex: 4,
          }}
        >
          <DashboardContainer>
            <Box sx={{ pb: 2 }}>
              <SummaryChart data={responseData} />
            </Box>
          </DashboardContainer>
        </Box> */}
      </Box>

      { !isMobile ? (
          <DashboardContainer>
          <Box sx={{ pb: 2 }}>
            <SummaryChart data={responseData} />
          </Box>
        </DashboardContainer>
      ): (
        <Box sx={{ pb: 2 }}>
          <SummaryChart data={responseData} />
        </Box>
      )}

      { !isMobile ? (
         <DashboardContainer>
         <Loader open={loading} />
 
         <Grid item xs={12} md={7} lg={8}>
           <Grid container alignItems="center" justifyContent="space-between">
             <Grid item>
               <Typography variant="h6" mb={3}>Recent Applications</Typography>
             </Grid>
             <Grid item />
           </Grid>
           <OrderTable data={data} resData={recent} role={role} />
         </Grid>
       </DashboardContainer>
      ) : (
        <>
        <Loader open={loading} />

        <Grid item xs={12} md={7} lg={8}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h6" mb={3} mt={2}>Recent applications</Typography>
            </Grid>
            <Grid item />
          </Grid>
          <OrderTable data={data} resData={recent} role={role} />
        </Grid>
        </>
      )}    
    </AdminLayout>
    </>
  );
};

export default AdminDashboard;
