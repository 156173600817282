import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import TextInput from "../../../core/components/Input/TextInput";
import { FontWeight, TextFieldType } from "../../../core/types";
import BaseAuthTemplate from "../../../core/components/CommonTemplate/BaseAuthTemplate";
import { Formik } from "formik";
import { forgetPasswordSchema } from "../../../core/validations/formSchema";
import { useNavigate } from "react-router-dom";
import { OTPSCREEN } from "../../../router/config";

const ForgotPassword = () => {
  const navigate = useNavigate();

  return (
    <BaseAuthTemplate>
      <Box height={"100%"} mt={15} mb={20} sx={{ paddingLeft: 7 }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box ml={3}>
            <Typography
              fontWeight={FontWeight.BOLD}
              padding={1}
              color={"grey"}
              fontSize={18}
            >
              RECOVER ACCOUNT
            </Typography>
            <Typography padding={1} fontWeight={FontWeight.BOLD} fontSize={25}>
              Forgot your password?
            </Typography>
            <Typography
              padding={1}
              color={"grey"}
              fontWeight={FontWeight.MEDIUM}
              fontSize={14}
            >
              Enter your email address below and we’ll get you back on track.
            </Typography>
          </Box>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={forgetPasswordSchema}
            onSubmit={(value) => {
              console.log(value);
              navigate(OTPSCREEN);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <TextInput
                    label="Enter your email"
                    onChange={handleChange}
                    name="email"
                    type={TextFieldType.EMAIL}
                    value={values.email}
                    onBlur={handleBlur}
                    error={
                      errors.email && touched.email && errors.email
                        ? true
                        : false
                    }
                    helperText={errors.email && touched.email && errors.email}
                    textFieldHeigt={50}
                  />
                </Box>
                <Box paddingLeft={33} paddingTop={1}>
                  <Button type="submit" variant="contained">
                    Send rest link
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </BaseAuthTemplate>
  );
};

export default ForgotPassword;
