import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Button,
  Drawer,
  List,
  ListItemIcon,
  CssBaseline,
  Box,
  useMediaQuery,
  ListItemButton,
} from "@mui/material";
import { useGoogleLogout } from "react-google-login";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { FontWeight } from "../../../../core/types";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../store/Auth/authSlice";
import {
  adminDrawerList,
  auditorDrawerList,
  decisionDrawerList,
  userDrawerList,
} from "../../constants";
import { Link, useLocation } from "react-router-dom";
import { USERTYPE } from "../../../../core/constants";
import { logo } from "../../../../core/Images";
import DrawerMenu from "./components/Drawer";
import MobileBottomNavbar from "../../../../core/components/MobileBottomNavbar";

const drawerWidth = 250;

const AdminLayout = ({ children }: any) => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [drawerList, setDrawerList] = useState<any>(adminDrawerList);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const user = useSelector((state: any) => state.auth.user);

  const { role } = user;

  const dispatch = useDispatch();

  const toggleDrawer = (open: any) => () => {
    setDrawerOpen(open);
  };

  useEffect(() => {
    setDrawerOpen(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    switch (role) {
      case USERTYPE.ADMIN:
        setDrawerList(adminDrawerList);
        break;
      case USERTYPE.AUDITOR:
        setDrawerList(auditorDrawerList);
        break;
      case USERTYPE.DECISION:
        setDrawerList(decisionDrawerList);
        break;
      case USERTYPE.USER:
        setDrawerList(userDrawerList);
        break;
    }
  }, [role]);

  useEffect(() => {
    const currentPath = location.pathname;
    const currentIndex = drawerList.findIndex(
      (item: any) => item.nav === currentPath
    );
    setSelectedIndex(currentIndex !== -1 ? currentIndex : 0);
  }, [location.pathname, drawerList]);

  const clientId =
    "830136945700-4sukjrick8hl7m10rlj7al7jhgfmntvo.apps.googleusercontent.com";

  const onLogoutSuccess = () => {
    console.log("Logout successful");
  };

  const onFailure = () => {
    console.log("Logout failed");
  };

  const { signOut, loaded } = useGoogleLogout({
    clientId: clientId,
    onLogoutSuccess,
    onFailure,
    cookiePolicy: "single_host_origin",
    fetchBasicProfile: false, // optional, if you don’t need profile info
    accessType: "offline", // optional
    scope: "",
  });

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        maxWidth: "100%",
        height: "100vh",
        backgroundColor: "white",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <CssBaseline />
      <AppBar position="fixed" style={{ background: "white" }}>
        <Toolbar>
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon style={{ color: "black" }} />
            </IconButton>
          )}
          <Typography variant="h6" style={{ flexGrow: 1 }}></Typography>
          <Avatar
            alt={user.firstName}
            src="/static/images/avatar/2.jpg"
            style={{ color: "black" }}
          />
          <Button
            color="inherit"
            startIcon={<LogoutIcon />}
            style={{ marginLeft: 10, color: "black" }}
            onClick={() => {
              signOut();
              dispatch(logout());
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            //backgroundColor: "#2c2c2c",
          },
        }}
      >
        <Box style={{ width: drawerWidth }}>
          <Box mt={3} ml={2} mb={4}>
            <Box
              sx={{
                backgroundImage: `url(${logo})`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: `200px 50px`,
                height: 50,
                width: 200,
              }}
            />
          </Box>
          <DrawerMenu drawerList={drawerList} selectedIndex={selectedIndex} />
          {/* {drawerList && (
            <List>
              {drawerList.map((item: any, index: number) => (
                <ListItemButton
                  sx={{
                    padding: 2,
                  }}
                  component={Link}
                  to={item.nav}
                >
                  <ListItemIcon
                    sx={{
                      color: selectedIndex == index ? "#4880FF" : "#72777a",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <Typography
                    fontWeight={FontWeight.MEDIUM}
                    fontSize={14}
                    color={selectedIndex == index ? "#4880FF" : "#72777a"}
                  >
                    {item.label}
                  </Typography>
                </ListItemButton>
              ))}
            </List>
          )} */}
        </Box>
      </Drawer>
      <Box
        style={{
          flexGrow: 1,
          padding: 24,
          paddingTop: 20,
          marginTop: 60,
          width: "100%",
          height: "93vh",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
      {/* { isMobile &&  <>
      <Box>
        <MobileBottomNavbar />
      </Box>
    </>
    } */}
    </Box>
  );
};

export default AdminLayout;
