import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AdminLayout from "../dashboard/components/AdminLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { FontWeight, INPUTFIELDTYPE, TextFieldType } from "../../core/types";
import DashboardContainer from "../../core/components/DashboardContainer";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Field, FieldArray, Formik } from "formik";
import { adminReviewShcema } from "../../core/validations/formSchema";
import {
  documentsReceviedParticulars,
  existingCertificationStatis,
  impartialityAssessementList,
  increaseinManDays,
  levelOfIntegration,
  mutiSiteInfo,
  particulars,
  reviewInitialValues,
  seasonalActivities,
  usageOfMarkLogo,
} from "./constants";
import CommonGrid from "../onBoarding/Components/common/CommonGrid";
import TextWithLabel from "../../core/components/TextWithLabel";
import RowTextField from "./component/RowTextFields";
import { REVIEWSTATUS } from "../../router/config";
import useAsyncRequest from "../../networking/useAsyncRequest";
import { applicationReview } from "../../store/Application/applicationService";
import Loader from "../../core/components/Loader";
import { Fragment, useEffect, useState } from "react";
import ManDayCalculation from "./component/ManDayCalculations";
import ParticularsField from "./component/ParticularField";
import TextInputLight from "../../core/components/Input/TextInputLight";

const AdminReview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state;
  const { applicationId, companyDetail } = data;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { execute, loading } = useAsyncRequest(applicationReview);

  const handleResponse = (res: any) => {
    const { status } = res;
    if (status == 200) {
      navigate(REVIEWSTATUS, {
        state: { data: data },
      });
    }
  };

  const steps = [
    "Logo",
    "Man Power Calculatoins",
    "Increase in Man-Days",
    "Assessments",
    "Review of Audit performance",
    "Particulars",
    "Seasonal Activities",
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [particularList, setParticularList] = useState<any>(particulars);
  const [docParticularList, setDocParticularList] = useState<any>(
    documentsReceviedParticulars
  );

  const calculateEmployeManDays = (input: number): number => {
    if (input < 20) return 0;
    if (input >= 20 && input <= 49) return 0.5;
    if (input >= 50 && input <= 79) return 1.0;
    if (input >= 80 && input <= 199) return 1.5;
    if (input >= 200 && input <= 499) return 2.0;
    if (input >= 500 && input <= 899) return 2.5;
    if (input >= 900 && input <= 1299) return 3.0;
    if (input >= 1300 && input <= 1699) return 3.5;
    if (input >= 1700 && input <= 2999) return 4.0;
    if (input >= 3000 && input <= 5000) return 4.5;
    if (input > 5000) return 5.0;
    return 0;
  };

  useEffect(() => {
    const {
      siteDetails,
      noOfWorkShifts,
      noOfEmployeePerShift,
      totalNumberOfSites,
    } = companyDetail;
    const totalHCCAP = siteDetails.reduce(
      (sum: any, site: any) => sum + site.item,
      0
    );
    if (totalHCCAP) {
      if (reviewInitialValues.manPowerCalculation[1]) {
        reviewInitialValues.manPowerCalculation[1].auditManDays =
          totalHCCAP * 0.5;
      }
    }
    const numberOfEmployes = noOfWorkShifts * noOfEmployeePerShift;
    let totalEmployMandays = calculateEmployeManDays(numberOfEmployes);
    if (numberOfEmployes) {
      if (reviewInitialValues.manPowerCalculation[3]) {
        reviewInitialValues.manPowerCalculation[3].auditManDays =
          totalEmployMandays;
      }
    }
    if (totalNumberOfSites) {
      if (reviewInitialValues.manPowerCalculation[4]) {
        reviewInitialValues.manPowerCalculation[4].auditManDays =
          totalNumberOfSites;
      }
    }
  }, [activeStep, navigate, location, companyDetail]);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const detailField = (title: string, subtitle: any) => {
    const isBool = typeof subtitle === "boolean";
    let subTitle = "";
    if (isBool) {
      subTitle = subtitle === true ? "Yes" : "No";
    } else {
      subTitle = subtitle ? subtitle : "-";
    }
    return (
      <Grid item xs={2} sm={4} md={4}>
        {title && <TextWithLabel title={title} subTitle={subTitle} />}
      </Grid>
    );
  };

  const heading = (title: string) => {
    return (
      <Typography
        variant="h6"
        fontSize={isMobile ? 16 : 18}
        fontWeight={FontWeight.BOLD}
      >
        {title}
      </Typography>
    );
  };

  const fieldText = ({ field, form, ...props }: any) => {
    return (
      <Box height={40}>
        <TextField
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "#000",
              fontFamily: "Arial",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
                borderWidth: 1,
                borderRadius: 3,
              },
            },
            "& .MuiInputLabel-outlined": {
              color: "#2e2e2e",
            },
            width: "100%",
          }}
          {...field}
          {...props}
        />
      </Box>
    );
  };

  const manPowerFieldText = ({ field, form, ...props }: any) => {
    return (
      <Box height={50} width={50}>
        <TextField
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "#000",
              fontFamily: "Arial",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#000",
                borderWidth: 1,
                borderRadius: 2,
              },
            },
            "& .MuiInputLabel-outlined": {
              color: "#2e2e2e",
            },
            width: "100%",
          }}
          {...field}
          {...props}
        />
      </Box>
    );
  };

  return (
    <AdminLayout>
      <Typography variant="h4" fontWeight={FontWeight.BOLD}>
        {companyDetail.nameOfCompany}
      </Typography>

      <DashboardContainer>
        <Stepper
          activeStep={activeStep}
          sx={{
            mt: 2,
            mb: 5,
            pr: isMobile ? 10 : 17,
            pl: isMobile ? 10 : 17,
          }}
        >
          {steps.map((label, index) => {
            if (isMobile) {
              if (index !== activeStep) {
                return null;
              }
            }
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (index == 2 || index == 6) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <Loader open={loading} />
        <Formik
          initialValues={reviewInitialValues}
          validationSchema={adminReviewShcema}
          onSubmit={(value) => {
            const payload = {
              applicationId: applicationId,
              companyId: companyDetail.id,
              ...value,
            };
            execute(payload, handleResponse);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              {activeStep == 2 || activeStep == 6 ? (
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={handleSkip}
                    sx={{ mr: 1 }}
                  >
                    Skip
                  </Button>
                </Box>
              ) : null}
              {activeStep === steps.length ? (
                <Fragment>
                  <Box
                    mt={4}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <CheckCircleRoundedIcon
                      sx={{ height: 150, width: 150, color: "green" }}
                    />
                    <Typography
                      mt={2}
                      fontSize={25}
                      fontWeight={FontWeight.BOLD}
                    >
                      Review Completed
                    </Typography>
                    <Typography fontSize={16} fontWeight={FontWeight.BOLD}>
                      Please submit to continue
                    </Typography>
                  </Box>

                  {/* <SummaryComponent data={values} /> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pt: 2,
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      color="inherit"
                      variant="contained"
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Button variant="contained" onClick={handleReset}>
                      Reset
                    </Button>
                  </Box>
                  <Box
                    pt={10}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Box>
                </Fragment>
              ) : activeStep === 0 ? (
                <Fragment>
                  <Typography
                    variant="h6"
                    fontSize={isMobile ? 16 : 18}
                    fontWeight={FontWeight.BOLD}
                  >
                    Comment on usage of Mark / Logo, display of certificate for
                    its appropriateness and validity on the Client’s website
                  </Typography>
                  <Box width={"85%"}>
                    <CommonGrid
                      handleChange={handleChange}
                      value={values}
                      onBlur={handleBlur}
                      paddingTop={{ xs: 2, md: 2 }}
                      error={errors}
                      list={usageOfMarkLogo}
                      touched={touched}
                      filedType={INPUTFIELDTYPE.COMPANYDETAILCHECK}
                    />
                  </Box>
                  <Box pl={isMobile ? 2 : 3}>
                    <TextInputLight
                      type={TextFieldType.TEXT}
                      name="markLogoRemarks"
                      label="Remarks"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline={true}
                      row={2}
                      textFieldHeigt={40}
                      width={isMobile ? "100%" : "35%"}
                    />
                  </Box>
                  <Box pt={5} pl={isMobile ? 2 : 3}>
                    {heading(" Particulars w.r.t. Category")}
                    <Grid
                      container
                      spacing={4}
                      alignItems={"baseline"}
                      columns={{ xs: 2, sm: 12, md: 16 }}
                      sx={{
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}
                    >
                      {detailField("Category", "Food")}
                      {detailField("Sub-Category", "Food")}
                      {detailField("Season of Operation", "All Season")}
                    </Grid>
                    {heading("Information about the Accreditation")}
                    <Grid
                      container
                      spacing={4}
                      alignItems={"baseline"}
                      columns={{ xs: 2, sm: 12, md: 16 }}
                      sx={{
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}
                    >
                      {detailField("Accreditation Requested by Client", "ALL")}
                      {detailField("Accreditation Available", "N/A")}
                      {detailField("Accreditation To Be Granted", "ALL")}
                    </Grid>
                  </Box>
                </Fragment>
              ) : activeStep === 1 ? (
                <Fragment>
                  {heading("Calculation of Effective Man Power")}
                  <ManDayCalculation
                    handleChange={handleChange}
                    values={values}
                    companyDetail={companyDetail}
                  />
                  {/* <TableContainer sx={{ mb: 4 }}>
                    <Table
                      sx={{
                        width: "100%",
                        mb: 2,
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={7} align="center"></TableCell>
                          <TableCell colSpan={2}>Stage 1</TableCell>
                          <TableCell colSpan={2}>Stage 2/Renewal</TableCell>
                          <TableCell colSpan={2}>SA# 1</TableCell>
                          <TableCell colSpan={2}>SA# 2</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Scheme (s)</TableCell>
                          <TableCell>Location/Sites</TableCell>
                          <TableCell>Total ManPower</TableCell>
                          <TableCell>Effective Manpower</TableCell>
                          <TableCell>Manday</TableCell>
                          <TableCell>Increase %</TableCell>
                          <TableCell>Decrease %</TableCell>
                          <TableCell>on</TableCell>
                          <TableCell>of</TableCell>
                          <TableCell>on</TableCell>
                          <TableCell>of</TableCell>
                          <TableCell>on</TableCell>
                          <TableCell>of</TableCell>
                          <TableCell>on</TableCell>
                          <TableCell>of</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {manPowerCalculation.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.scheme}</TableCell>
                            <TableCell>{row.location}</TableCell>
                            <TableCell>
                              <Field
                                name={`manPowerCalculation[${index}].totalManPower`}
                                as={manPowerFieldText}
                                onChange={() => {
                                  setFieldValue(
                                    `manPowerCalculation[${index}].scheme`,
                                    row.scheme
                                  );
                                  setFieldValue(
                                    `manPowerCalculation[${index}].location`,
                                    row.location
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`manPowerCalculation[${index}].effectiveManPower`}
                                as={manPowerFieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`manPowerCalculation[${index}].manday`}
                                as={manPowerFieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`manPowerCalculation[${index}].increase`}
                                as={manPowerFieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`manPowerCalculation[${index}].decrease`}
                                as={manPowerFieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`manPowerCalculation[${index}].stageOneOn`}
                                as={manPowerFieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`manPowerCalculation[${index}].stageOneOff`}
                                as={manPowerFieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`manPowerCalculation[${index}].stageTwoOn`}
                                as={manPowerFieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`manPowerCalculation[${index}].stageTwoOff`}
                                as={manPowerFieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`manPowerCalculation[${index}].saOneOn`}
                                as={manPowerFieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`manPowerCalculation[${index}].saOneOff`}
                                as={manPowerFieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`manPowerCalculation[${index}].saTwoOn`}
                                as={manPowerFieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`manPowerCalculation[${index}].saTwoOff`}
                                as={manPowerFieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer> */}
                  {/* {heading("Standard Information")}
                  <TextInput
                    mt={2}
                    type={TextFieldType.TEXT}
                    name="manPowerInfo"
                    label="Man-Power Information:"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline={true}
                    row={2}
                    textFieldHeigt={40}
                    width={"85%"}
                  /> */}
                  {/* <TextInput
                    mt={2}
                    type={TextFieldType.TEXT}
                    name="justificationOfmanPower"
                    label="Justification for effective manpower:"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline={true}
                    row={2}
                    textFieldHeigt={40}
                    width={"85%"}
                  />
                  <TextInput
                    mt={2}
                    type={TextFieldType.TEXT}
                    name="effictiveManpower"
                    label="Effective Manpower"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline={true}
                    row={2}
                    textFieldHeigt={40}
                    width={"85%"}
                  />
                  <TextInput
                    mt={2}
                    type={TextFieldType.TEXT}
                    name="complexityType"
                    label="Complexity Type (High, Medium & Low)"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline={true}
                    row={2}
                    textFieldHeigt={40}
                    width={"85%"}
                  />
                  <TextInput
                    mt={2}
                    type={TextFieldType.TEXT}
                    name="noOfHaccpStudies"
                    label="No. of HACCP Studies"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline={true}
                    row={2}
                    textFieldHeigt={40}
                    width={"85%"}
                  />
                  <TextInput
                    mt={2}
                    type={TextFieldType.TEXT}
                    name="activityBeloningToLegalEntity"
                    label="Any Activity belonging to the same legal entity of the parent organization applied for Certification"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline={true}
                    row={2}
                    textFieldHeigt={40}
                    width={"85%"}
                  />
                  <TextInput
                    mt={2}
                    type={TextFieldType.TEXT}
                    name="noOfDairyUnits"
                    label="For Dairy Units: Number of Collection Centres/ Milk Chilling centres belonging to the same legal entity of the parent organization applied for Certification"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline={true}
                    row={2}
                    textFieldHeigt={40}
                    width={"85%"}
                  />
                  <TextInput
                    mt={2}
                    type={TextFieldType.TEXT}
                    name="forBRCGS"
                    label="For BRCGS : Announced or Unannounced"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline={true}
                    row={2}
                    textFieldHeigt={40}
                    width={"85%"}
                  /> */}
                </Fragment>
              ) : activeStep === 2 ? (
                <Fragment>
                  {heading("Increase in Man-days")}
                  <TableContainer>
                    <Table
                      sx={{
                        minWidth: 650,
                        width: "100%",
                        mb: 4,
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Standard</TableCell>
                          <TableCell align="center">Site Name</TableCell>
                          <TableCell align="center">
                            Criteria for Increase in audit time of management
                            systems [Food Safety Scheme(s)] Increasing Factor
                          </TableCell>
                          <TableCell align="center">%</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <FieldArray name="increaseinManDays">
                          {({ push, remove }) => (
                            <>
                              {increaseinManDays.map((row, index) => (
                                <TableRow
                                  key={row.siteName}
                                  sx={{
                                    border: 0,
                                  }}
                                >
                                  <TableCell sx={{ height: 60 }}>
                                    <Field
                                      name={`increaseinManDays[${index}].standard`}
                                      as={fieldText}
                                      onChange={() => {
                                        setFieldValue(
                                          `increaseinManDays[${index}].criteria`,
                                          row.criteria
                                        );
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell sx={{ height: 60, width:  "50%" }}>
                                    <Field
                                      name={`increaseinManDays[${index}].siteName`}
                                      as={fieldText}
                                      onChange={handleChange}
                                    />
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                  >
                                    <Field
                                      type="hidden"
                                      name={`increaseinManDays[${index}].criteria`}
                                    />
                                    {row.criteria}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    component="th"
                                    scope="row"
                                  >
                                    <Field
                                      name={`increaseinManDays[${index}].percent`}
                                      as={manPowerFieldText}
                                      onChange={handleChange}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TextInputLight
                    mt={2}
                    type={TextFieldType.TEXT}
                    name="totalTimeOnSIte"
                    label="Total Time onsite"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    width={ isMobile ? "100%" : 400}
                    textFieldHeigt={40}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      mt: 2,
                      width: "100%",
                    }}
                  >
                    <Typography>Stage 1 Mandays</Typography>
                    <Typography>Stage 2/RenewalMandays</Typography>
                  </Box>
                  <RowTextField
                    name1={"stage1Manday"}
                    label1={"Stage 1 Manday(s)"}
                    onBlur1={handleBlur}
                    onChange1={handleChange}
                    name2={"stage2Manday"}
                    label2={"Stage 2 Manday(s)"}
                    onBlur2={handleBlur}
                    onChange2={handleChange}
                  />
                  <RowTextField
                    name1={"stage1Onsite"}
                    label1={"Stage 1 Onsite"}
                    onBlur1={handleBlur}
                    onChange1={handleChange}
                    name2={"stage2Onsite"}
                    label2={"Stage 2 / Renewal Onsite"}
                    onBlur2={handleBlur}
                    onChange2={handleChange}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      mt: 2,
                      width: "100%",
                    }}
                  >
                    <Typography>Stage 1 Offsite</Typography>
                    <Typography>Stage 2 / Renewal Offsite</Typography>
                  </Box>
                  <RowTextField
                    name1={"stageOneauditPreparationOffsite"}
                    label1={"Audit Preparation"}
                    onBlur1={handleBlur}
                    onChange1={handleChange}
                    name2={"stageTwoauditPreparetionOffsite"}
                    label2={"Audit Preparation"}
                    onBlur2={handleBlur}
                    onChange2={handleChange}
                  />
                  <RowTextField
                    name1={"stageOneAuditReportingOffsite"}
                    label1={"Audit Reporting"}
                    onBlur1={handleBlur}
                    onChange1={handleChange}
                    name2={"stageTwoAuditReportingOffsite"}
                    label2={"Audit Reporting"}
                    onBlur2={handleBlur}
                    onChange2={handleChange}
                  />
                  <Box mt={4}>{heading("Surveillance Mandays")}</Box>
                  <RowTextField
                    name1={"surveillanceMandayOverall"}
                    label1={"Surveillance Manday(s) (Overall)"}
                    onBlur1={handleBlur}
                    onChange1={handleChange}
                    name2={"surveillanceMandayEach"}
                    label2={"Surveillance Manday(s) (Each)"}
                    onBlur2={handleBlur}
                    onChange2={handleChange}
                  />
                  <RowTextField
                    name1={"surveillanceOnsiteEach"}
                    label1={"Surveillance Onsite (Each)"}
                    onBlur1={handleBlur}
                    onChange1={handleChange}
                    name2={""}
                    label2={""}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      mt: 4,
                      width: "100%",
                    }}
                  >
                    <Typography>Surveillance Offsite (Each)</Typography>
                    <Box width={250}></Box>
                  </Box>
                  <RowTextField
                    name1={"auditPreparationSurveillanceEach"}
                    label1={"Audit Preparation"}
                    onBlur1={handleBlur}
                    onChange1={handleChange}
                    name2={"auditReportingSurveillanceEach"}
                    label2={"Audit Reporting"}
                    onBlur2={handleBlur}
                    onChange2={handleChange}
                  />
                </Fragment>
              ) : activeStep === 3 ? (
                <Fragment>
                  <Box mt={4}>{heading("Impartiality Assessments")}</Box>

                  <TableContainer>
                    <Table
                      sx={{
                        minWidth: 650,
                        width: "100%",
                        mb: 4,
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Possible Risk</TableCell>
                          <TableCell align="center">Value</TableCell>
                          <TableCell align="center">
                            Assessment / Mention Name of Resources
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {impartialityAssessementList.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              border: 0,
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.possibleRisk}
                            </TableCell>
                            <TableCell sx={{ height: 60 }}>
                              <Field
                                name={`impartialityAssessementList[${index}].value`}
                                as={fieldText}
                                onChange={() => {
                                  setFieldValue(
                                    `impartialityAssessementList[${index}].possibleRisk`,
                                    row.possibleRisk
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell sx={{ height: 60 }}>
                              <Field
                                name={`impartialityAssessementList[${index}].assessment`}
                                as={fieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Fragment>
              ) : activeStep === 4 ? (
                <Fragment>
                  <Box mt={4}>
                    {heading("Review of Last 3 years Audit performance:")}
                  </Box>
                  <TextInputLight
                    mt={4}
                    type={TextFieldType.TEXT}
                    name="verificaionOfConclusionMadeByTeamLeaderLastServeillanceAudit"
                    label="Verification of conclusion made by Team Leader of Last Surveillance Audit"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline={true}
                    row={2}
                    width={"100%"}
                    textFieldHeigt={40}
                  />
                  <TextInputLight
                    mt={6}
                    type={TextFieldType.TEXT}
                    name="significantChangesInManagementSystem"
                    label="Any significant changes in the Management System / Operations / Production Line / Location vis-à-vis last audit"
                    onChange={handleChange}
                    width={"100%"}
                    onBlur={handleBlur}
                    multiline={true}
                    row={2}
                    textFieldHeigt={40}
                  />
                  <TextInputLight
                    mt={6}
                    type={TextFieldType.TEXT}
                    name="infoFromExternalSources"
                    label="The information from “External Sources” (Public information), if any"
                    onChange={handleChange}
                    width={"100%"}
                    onBlur={handleBlur}
                    multiline={true}
                    row={2}
                    textFieldHeigt={40}
                  />
                  <Box mt={4}>{heading("Existing Certification Status")}</Box>
                  <Box width={"100%"}>
                    <CommonGrid
                      handleChange={handleChange}
                      value={values}
                      onBlur={handleBlur}
                      paddingTop={{ xs: 2, md: 2 }}
                      error={errors}
                      list={existingCertificationStatis}
                      touched={touched}
                      filedType={INPUTFIELDTYPE.TEXT}
                    />
                  </Box>
                  <Box mt={4}>{heading("Multisite Information")}</Box>
                  <Box width={"100%"}>
                    <CommonGrid
                      handleChange={handleChange}
                      value={values}
                      onBlur={handleBlur}
                      paddingTop={{ xs: 2, md: 2 }}
                      error={errors}
                      list={mutiSiteInfo}
                      touched={touched}
                      filedType={INPUTFIELDTYPE.TEXT}
                    />
                  </Box>
                  <Box mt={4}>{heading("Level of Integration")}</Box>
                  <Box
                    pt={1}
                    display={"flex"}
                    alignItems={"flex-start"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    {levelOfIntegration.map((item, index) => {
                      return (
                        <FormControlLabel
                          style={{ paddingTop: 5 }}
                          control={
                            <Checkbox
                              name={item.name}
                              onChange={() => {
                                setFieldValue(
                                  `levelOfIntegration[${index}].name`,
                                  item.name
                                );
                              }}
                              onBlur={handleBlur}
                            />
                          }
                          label={
                            <Typography fontSize={15}>{item.label}</Typography>
                          }
                        />
                      );
                    })}
                  </Box>
                </Fragment>
              ) : activeStep === 5 ? (
                <Fragment>
                  <Box mt={4}>
                    <Box mt={4}>{heading("Particulars")}</Box>
                    <ParticularsField
                      rows={particularList}
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      fieldName={"particulars"}
                    />

                    <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"end"}
                      pt={2}
                    >
                      <Button
                        onClick={() => {
                          setParticularList((prev: any) => [
                            ...prev,
                            prev.length + 1,
                          ]);
                        }}
                      >
                        + Add More
                      </Button>
                    </Box>
                    <Box mt={4} width={"85%"}>
                      {heading(
                        "Check whether the following documents are received along with the duly filled Questionnaire : ( FOR slaughterhouse only)"
                      )}
                    </Box>
                    <ParticularsField
                      rows={docParticularList}
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                      fieldName={"slaughterHouseDocumentsRecieved"}
                    />

                    <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"end"}
                      pt={2}
                    >
                      <Button
                        onClick={() => {
                          setDocParticularList((prev: any) => [
                            ...prev,
                            prev.length + 1,
                          ]);
                        }}
                      >
                        + Add More
                      </Button>
                    </Box>
                    <Box mt={4}>
                      <TextInputLight
                        type={TextFieldType.TEXT}
                        name="commentsRemarks"
                        label="Comments / Remarks"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline={true}
                        row={2}
                        width={"100%"}
                      />
                    </Box>
                  </Box>
                </Fragment>
              ) : (
                <Fragment>
                  {heading(
                    "Seasonal Activities : [such as harvesting activities etc.]"
                  )}
                  <TableContainer>
                    <Table
                      sx={{
                        minWidth: 650,
                        width: "100%",
                        mb: 4,
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Sites Name</TableCell>
                          <TableCell align="center">Activities</TableCell>
                          <TableCell align="center">No. of shifts</TableCell>
                          <TableCell align="center">Shifts Name</TableCell>
                          <TableCell align="center">Full time Emp.</TableCell>
                          <TableCell align="center">Part time Emp.</TableCell>
                          <TableCell align="center">Contract Emp.</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {seasonalActivities.map((row, index) => (
                          <TableRow
                            key={row.siteName}
                            sx={{
                              border: 0,
                            }}
                          >
                            <TableCell sx={{ height: 60 }}>
                              <Field
                                name={`seasonalActivities[${index}].siteName`}
                                as={fieldText}
                                onChange={() => {
                                  setFieldValue(
                                    `seasonalActivities[${index}].activeties`,
                                    row.activeties
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.activeties}
                            </TableCell>
                            <TableCell sx={{ height: 60 }}>
                              <Field
                                name={`seasonalActivities[${index}].noOfShifts`}
                                as={fieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`seasonalActivities[${index}].shiftName`}
                                as={fieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`seasonalActivities[${index}].fullTimeEmp`}
                                as={fieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`seasonalActivities[${index}].partTimeEmp`}
                                as={fieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`seasonalActivities[${index}].contractEmp`}
                                as={fieldText}
                                onChange={handleChange}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Fragment>
              )}
              {activeStep !== 7 && (
                <Box sx={{ display: "flex", flexDirection: "row", pt: 10 }}>
                  <Button
                    color="inherit"
                    variant="contained"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />

                  <Button
                    onClick={() => {
                      handleNext();
                    }}
                    variant="contained"
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Box>
              )}
            </form>
          )}
        </Formik>
      </DashboardContainer>
    </AdminLayout>
  );
};

export default AdminReview;
