import { Box } from "@mui/material";

const DashboardContainer = ({ children }: any) => {
  return (
    <Box
      sx={{
        flex: 1,
        flexGrow: 1,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        // boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        borderRadius: 5,
        padding: 3,
        mt: 2,
      }}
    >
      {children}
    </Box>
  );
};
export default DashboardContainer;
