import { useRef, useState } from "react";
import { Box, Button, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FontWeight } from "../../types";

const FileComponent = ({ onSelectFile }: any) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event: any) => {
    hiddenFileInput.current?.click();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const filesArray = Array.from(selectedFiles);
      setFiles(filesArray);
      onSelectFile(filesArray);
    }
  };
  const handleFileRemove = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    onSelectFile(updatedFiles);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);

    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles) {
      setFiles(droppedFiles);
      onSelectFile(droppedFiles);
    }
  };

  return (
    <Box
      sx={{ width: isMobile ? "100%" : 620 }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <Typography variant="body1" fontWeight={FontWeight.BOLD}>
        Bussineess Activity (Proccess)
      </Typography>
      <Typography variant="body2" pb={1}>
        Attach your Process Flow Diagram, Product Profile/Companay Profile /
        Service Brochure, and you can upload up to 5 files max
      </Typography>
      <Box
        sx={{
          border: "1.8px dashed #1849D6",
          padding: 5,
          borderRadius: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {files &&
          (files.length == 1 ? (
            <Box
              sx={{
                paddingLeft: 2,
                border: "1px solid #2196f3",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Typography fontSize={14} color={"#2c2c2c"}>
                {files[0].name}
              </Typography>
              <IconButton
                onClick={() => {
                  handleFileRemove(0);
                }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Box>
          ) : (
            <Grid
              spacing={2}
              container
              columns={{ xs: 2, sm: 8, md: 8 }}
              sx={{
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              {files.map((item: any, index: number) => {
                return (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <Box
                      sx={{
                        paddingLeft: 2,
                        border: "1px solid #2196f3",
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography fontSize={14} color={"#2c2c2c"}>
                        {item?.name}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          handleFileRemove(index);
                        }}
                      >
                        <CloseRoundedIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          ))}
        <Typography variant="body2" pb={1}>
          {dragging
            ? "Drop your files here"
            : "Drag your file(s) to start uploading"}
        </Typography>
        <Typography variant="body2" pb={1}>
          OR
        </Typography>
        <Button
          variant="outlined"
          sx={{
            height: 30,
            borderRadius: 3,
            borderWidth: 2,
            fontSize: 10,
            textTransform: "none",
          }}
          onClick={handleClick}
        >
          Browse Files
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
            multiple
          />
        </Button>
      </Box>
    </Box>
  );
};

export default FileComponent;
