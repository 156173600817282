import { TextFieldType } from "../../../core/types";

export const cerficateType: CompanyDetail[] = [
  {
    name: "fresh",
    label: "Fresh Certifications",
  },
  {
    name: "renewal",
    label: "Renewal Certifications",
  },
  {
    name: "transfer",
    label: "Transfer Certificate",
  },
  {
    name: "scopeExtension",
    label: "Scope Extension",
  },
];

export const certificationType: CompanyDetail[] = [
  {
    name: "isoStandard",
    label: "ISO Standard",
  },
  {
    name: "fssc2200",
    label: "FSSC 22000",
  },
  {
    name: "fssaiSchedule4",
    label: "FSSAI Schedule 4",
  },
  {
    name: "gsoStandard",
    label: "GSO Standard",
  },
  {
    name: "fsscDevelopmentProgramme",
    label: "FSSC Development Programme",
  },
  {
    name: "hygieneRating",
    label: "Hygiene Rating",
  },
  {
    name: "fassai3rdPartySaftyAudits",
    label: "FSSAI 3rd Party Safety Audits",
  },
  {
    name: "brcgsFoodSafty",
    label: "BRCGS-Food Safety",
  },
  {
    name: "brcgsStart",
    label: "BRCGS-Start",
  },
  {
    name: "malaysiaStandrad",
    label: "Malaysia Standrad",
  },
  {
    name: "indianConformityScheme",
    label: "INDIAN CONFORMITY ASSESSMENT SCHEME (I-CAS)",
  },
];

export const certificationInitialValue: Record<string, any> = {
  radio: "",
  fresh: false,
  renewal: false,
  transfer: false,
  scopeExtension: false,
};

export interface CompanyDetail {
  type?: TextFieldType;
  name?: string;
  label?: string;
  value?: string; // Optional property
  row?: number; // Optional property
  multiline?: boolean; // Optional property
}

export const companyDetailsOne: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "nameOfCompany",
    label: "Name of the Company*",
    value: "",
  },
  {
    type: TextFieldType.TEXT,
    name: "telephone",
    label: "Telephone No*",
  },
  {
    type: TextFieldType.TEXT,
    name: "address",
    label: "Address*",
    row: 2,
    multiline: true,
  },
  {
    type: TextFieldType.TEXT,
    name: "invoiceAddress",
    label: "Invoice Address*",
    row: 2,
    multiline: true,
  },
  {
    type: TextFieldType.TEXT,
    name: "pinCode",
    label: "Pin Code*",
  },
  {
    type: TextFieldType.TEXT,
    name: "invoicePinCode",
    label: "Invoice Pin Code*",
  },
];

export const companyDetailsTwo: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "panNo",
    label: "PAN",
  },
  {
    type: TextFieldType.EMAIL,
    name: "email",
    label: "E-Mail*",
  },
  {
    type: TextFieldType.TEXT,
    name: "gstNo",
    label: "GST No",
  },
  {
    type: TextFieldType.TEXT,
    name: "tanNo",
    label: "TAN No",
  },
  {
    type: TextFieldType.TEXT,
    name: "website",
    label: "Website*",
  },
  {
    type: TextFieldType.TEXT,
    name: "annualTurnOver",
    label: "Annual Turnover",
  },
];

export const companyDetailsThree: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "holdingCompany",
    label: "Holding Company",
  },
];

export const companyDetails: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "nameOfCompany",
    label: "Name of the Company*",
    value: "",
  },
  {
    type: TextFieldType.TEXT,
    name: "telephone",
    label: "Telephone No*",
  },
  {
    type: TextFieldType.TEXT,
    name: "panNo",
    label: "PAN",
  },
  {
    type: TextFieldType.EMAIL,
    name: "email",
    label: "E-Mail*",
  },
  {
    type: TextFieldType.TEXT,
    name: "gstNo",
    label: "GST No",
  },
  {
    type: TextFieldType.TEXT,
    name: "pinCode",
    label: "Pin Code*",
  },
  {
    type: TextFieldType.TEXT,
    name: "tanNo",
    label: "TAN No",
  },
  {
    type: TextFieldType.TEXT,
    name: "website",
    label: "Website*",
  },
  {
    type: TextFieldType.TEXT,
    name: "annualTurnOver",
    label: "Annual Turnover",
  },
  {
    type: TextFieldType.TEXT,
    name: "address",
    label: "Address*",
    row: 2,
    multiline: true,
  },
  {
    type: TextFieldType.TEXT,
    name: "invoiceAddress",
    label: "Invoice Address*",
    row: 2,
    multiline: true,
  },
];

export const companyManagementDetails: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "directorCeoPartner",
    label: "Director / CEO / Partner",
  },
  {
    type: TextFieldType.TEXT,
    name: "directorCeoPartnerMobile",
    label: "Mobile",
  },
  {
    type: TextFieldType.EMAIL,
    name: "directorCeoPartnerEmail",
    label: "E-Mail",
  },
  {
    type: TextFieldType.TEXT,
    name: "foodSaftyTeamLeaderMR",
    label: "Food Safty Team Leader / MR",
  },
  {
    type: TextFieldType.TEXT,
    name: "foodSaftyTeamLeaderMRMobile",
    label: "Mobile",
  },
  {
    type: TextFieldType.EMAIL,
    name: "foodSaftyTeamLeaderMREmail",
    label: "E-Mail",
  },
];

export const previousCertification: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "previousCertificateDetails",
    label:
      "Food Safty Team LeStandard certified for / Certificate to be transferred ader / MR",
  },
  {
    type: TextFieldType.TEXT,
    name: "previousCertificateValidity",
    label: "Certificate valid till",
  },
  {
    type: TextFieldType.TEXT,
    name: "previousCertificateBody",
    label: "Certification Body (Previous)",
  },
];

export const mainUnitDetails: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "noOfWorkShifts",
    label: "Number of work shifts",
  },
  {
    type: TextFieldType.TEXT,
    name: "noOfEmployeePerShift",
    label: "Number of Employee per shift",
  },
  {
    type: TextFieldType.TEXT,
    name: "outSourceProccess",
    label: "Outsourced Processes (if any)",
  },
];

export const mainUnitDetailsPart2: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "approvalsSatutoryAndRegulatoryRequirementsOrLegalStatus",
    label:
      "Approvals / Statutory & Regulatory requirements / Legal status e.g FSSAI License number with Validity for India",
  },
  {
    type: TextFieldType.TEXT,
    name: "activityBelongingToLegalEntity",
    label:
      "Any Activity belonging to the same legal entity of the parent organization applied for Certification",
  },
  {
    type: TextFieldType.TEXT,
    name: "noOfCollectionsCentersForMilk",
    label:
      "For Dairy Units: Number of Collection Centers/ Milk Chilling centers belonging to the same legal entity of the parent organization applied for Certification",
  },
];

export const mainUnitCheckBox: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "inHouseLaboratory",
    label: "In-house laboratory",
  },
  {
    type: TextFieldType.TEXT,
    name: "productDevelopment",
    label: "Product Development (R&D)",
  },
  {
    type: TextFieldType.TEXT,
    name: "foodSaftyHazard",
    label: "Intended user is Vulnerable to specific food safety hazard",
  },
];

export const siteDetails: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "mainSite",
    label: "Main Site",
  },
  {
    type: TextFieldType.TEXT,
    name: "siteTwo",
    label: "Site 2",
  },
  {
    type: TextFieldType.TEXT,
    name: "siteThree",
    label: "SIte 3",
  },
  {
    type: TextFieldType.TEXT,
    name: "siteFour",
    label: "Site 4",
  },
  {
    type: TextFieldType.TEXT,
    name: "siteFive",
    label: "Site 5",
  },
  {
    type: TextFieldType.TEXT,
    name: "siteSix",
    label: "Site 6",
  },
];

export const noOfOPRP: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "totalOPRPandCCP",
    label: "Total No. Of O-PRP & CCP more than 10",
  },
];

export const attachemntList: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "legalStatus",
    label: "Legal status",
  },
  {
    type: TextFieldType.TEXT,
    name: "listOfRawMaterials",
    label: "List of raw material(s)",
  },
  {
    type: TextFieldType.TEXT,
    name: "processDetails",
    label: "Process details",
  },
  {
    type: TextFieldType.TEXT,
    name: "foodSaftyManagementSystemIssues",
    label: "Food Safety Management system issues",
  },
  {
    type: TextFieldType.TEXT,
    name: "haccpAnalysisPlan",
    label: "HACCP analysis plan",
  },
  {
    type: TextFieldType.TEXT,
    name: "testReports",
    label: "Test Reports and/or Certificate of Analysis",
  },
  {
    type: TextFieldType.TEXT,
    name: "productLabels",
    label: "Product Labels/Artwork of Primary and Secondary Packages",
  },
];

export const attachemntListSlaughtherHouse: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "tradeOrSlaughterHouseLicense",
    label: "Trade/ Industrial/ Slaughterhouse License",
  },
  {
    type: TextFieldType.TEXT,
    name: "listOfSlaughterPerson",
    label: "List of Slaughter persons and their competency requirements",
  },
  {
    type: TextFieldType.TEXT,
    name: "proccessFlowChart",
    label: "Process Flowchart",
  },
  {
    type: TextFieldType.TEXT,
    name: "certificateForCriticalIngredients",
    label: "Certificate for Critical Ingredients",
  },
  {
    type: TextFieldType.TEXT,
    name: "healthCertificate",
    label: "Health Certificate",
  },
];

export const detailOfSite: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "noOfProducts",
    label: "No. of products/production lines",
  },
  {
    type: TextFieldType.TEXT,
    name: "buildingArea",
    label: "Building Area (in sq m)",
  },
  {
    type: TextFieldType.TEXT,
    name: "otherCertificateManagementSystem",
    label:
      "Any other certified Management Systems? E.g. ISO 9001:2015, ISO 14001:2015, ISO 45001:2018 etc.",
  },
];

export const position: CompanyDetail[] = [
  {
    type: TextFieldType.TEXT,
    name: "companyDetailsFilledByName",
    label: "Name",
  },
  {
    type: TextFieldType.TEXT,
    name: "companyDetailsFilledByPosition",
    label: "Position",
  },
];

export interface BillItem {
  id: number;
  name: string;
  item: string;
  amount: number;
}

export interface QuotationDetails {
  billBreakUp: BillItem[];
  gst: number;
  total: number;
}

export const quotationDetails: QuotationDetails = {
  billBreakUp: [
    {
      id: 1,
      name: "applicationFee",
      item: "Application Fee : ",
      amount: 500,
    },
    {
      id: 2,
      name: "accreditationFee",
      item: "Accreditation Fee : ",
      amount: 500,
    },
    {
      id: 3,
      name: "stageOneAuditFee",
      item: "Stage 1 Audit Fees (Inclusive of Document Review): ",
      amount: 500,
    },
    {
      id: 4,
      name: "stageTwoAuditFee",
      item: "Stage 2 Audit Fees (Inclusive of Certification Audit,Technical Review and issue of 2 original Certificates): ",
      amount: 500,
    },
    {
      id: 5,
      name: "documentReviewForRenuewalAudit",
      item: "Document Review for Renewal Audit (if Applicable) : ",
      amount: 500,
    },
    {
      id: 6,
      name: "RenewalAuditFree",
      item: "Renewal Audit Fees : ",
      amount: 500,
    },
    {
      id: 7,
      name: "surverillianceAuditOneFree",
      item: "Surveillance Audit # 1 Fees.This fee is inclusive of the auditand reporting : ",
      amount: 500,
    },
    {
      id: 8,
      name: "surverillianceAuditTwoFree",
      item: "Surveillance Audit # 2 Fees.This fee is inclusive of the auditand reporting : ",
      amount: 500,
    },
    {
      id: 9,
      name: "specialAuditFee",
      item: "Special Audit Fees   (if Applicable) Repeat Stage 1, Follow-up audit Full or   Part (Stage 2, Re-certification, Surveillance) Special Audit (Expanding scope   of certification already granted, Site addition, Short notice audit   or unannoused to investigate complaints or in   response to changes or follow-up for revocation of suspension All the repeat   stage 1, follow-up & special audit ",
      amount: 500,
    },
    {
      id: 10,
      name: "otherFees",
      item: "Any Other Fees as applicable : ",
      amount: 500,
    },
  ],
  gst: 200,
  total: 3600,
};

export const companyDetailsInitialValue: Record<string, any> = {
  nameOfCompany: "",
  telephone: "",
  panNo: "",
  address: "",
  invoiceAddress: "",
  pinCode: "",
  invoicePinCode: "",
  email: "",
  gstNo: "",
  tanNo: "",
  website: "",
  annualTurnOver: "",
  directorCeoPartner: "",
  directorCeoPartnerMobile: "",
  directorCeoPartnerEmail: "",
  foodSaftyTeamLeaderMR: "",
  foodSaftyTeamLeaderMRMobile: "",
  foodSaftyTeamLeaderMREmail: "",
  bussinessActivityDocument: "",
  previousCertificateDetails: "",
  previousCertificateValidity: "",
  previousCertificateBody: "",
  noOfWorkShifts: "",
  noOfEmployeePerShift: "",
  seasonOfOperation: "",
  LanguageOfCommunication: "",
  outSourceProccess: "",
  exclusions: "",
  approvalsSatutoryAndRegulatoryRequirementsOrLegalStatus: "",
  activityBelongingToLegalEntity: "",
  isLargeFirm: false,
  noOfCollectionsCentersForMilk: "",
  inHouseLaboratory: false,
  productDevelopment: false,
  foodSaftyHazard: false,
  attchmentListLawMaterials: false,
  totalNumberOfSites: "",
  noOfSiteLocationPermanent: "",
  noOfSiteLocationTemporary: "",
  additionalScopeExtensionPermanent: "",
  additionalScopeExtensionTemporary: "",
  noOfProducts: "",
  buildingArea: "",
  otherCertificateManagementSystem: "",
  mainSite: "",
  siteOne: "",
  siteTwo: "",
  siteThree: "",
  siteFour: "",
  siteFive: "",
  siteSix: "",
  totalOPRPandCCP: false,
  legalStatus: false,
  listOfRawMaterials: false,
  processDetails: false,
  foodSaftyManagementSystemIssues: false,
  haccpAnalysisPlan: false,
  testReports: false,
  productLabels: false,
  foodSchemeDocument: "",
  tradeOrSlaughterHouseLicense: false,
  listOfSlaughterPerson: false,
  proccessFlowChart: false,
  certificateForCriticalIngredients: false,
  healthCertificate: false,
  slaughterHouseDocument: "",
  holdingCompany: "",
};
