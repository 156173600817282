import { MuiOtpInput } from "mui-one-time-password-input";
import { useState } from "react";
import BaseAuthTemplate from "../../../core/components/CommonTemplate/BaseAuthTemplate";
import { Box, Button, Typography } from "@mui/material";
import { FontWeight } from "../../../core/types";

const OTPScreen = () => {
  const [otp, setOtp] = useState("");

  const handleChange = (newValue: any) => {
    setOtp(newValue);
  };

  return (
    <BaseAuthTemplate>
      <Box
        mt={20}
        mb={40}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <Typography
          padding={1}
          mb={5}
          color={"grey"}
          fontWeight={FontWeight.MEDIUM}
          fontSize={28}
        >
          Please enter the OTP sent to you E-mail ID
        </Typography>
        <MuiOtpInput width={400} value={otp} onChange={handleChange} />

        <Button
          sx={{ marginTop: 5 }}
          variant="outlined"
          disabled={otp.length !== 4}
        >
          Submit
        </Button>
      </Box>
    </BaseAuthTemplate>
  );
};

export default OTPScreen;
